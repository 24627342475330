import React, { useEffect, useState } from 'react';
import {
    GestureResponderEvent,
    Modal,
    Pressable,
    SafeAreaView,
    StyleSheet,
    View
} from 'react-native';
import { Close } from '../svg';
import LatButton from './LatButton';
import { LatCol } from './LatCol';
import { LatRow } from './LatRow';
import { Body, Heading3, TextLinkButton } from './StyledText';
import { useThemeColor } from './Themed';

export type LatModalButton = {
    type: 'Primary' | 'Secondary' | 'TextLink';
    text: string;
    disabled?: boolean;
    onPress?: (event: GestureResponderEvent) => void;
};

export type LatModalProps = View['props'] & {
    visible?: boolean;
    title?: string;
    description?: string;
    buttons?: LatModalButton[];
    contentBefore?: React.ReactNode;
    onClose?: () => void;
    tapOutsideToDismiss?: boolean;
};

const LatModal = (props: LatModalProps): JSX.Element => {
    const {
        style,
        visible,
        title,
        description,
        children,
        buttons,
        contentBefore,
        tapOutsideToDismiss = true,
        ...otherProps
    } = props;
    const [modalVisible, setModalVisible] = useState(visible ?? false);

    const defaultStyles = StyleSheet.create({
        backdrop: {
            position: 'absolute',
            backgroundColor: useThemeColor('dark'),
            opacity: 0.8,
            height: '100%',
            width: '100%'
        },
        modalView: {
            backgroundColor: useThemeColor('borders'),
            borderRadius: 8,
            marginTop: 32,
            marginHorizontal: 16,
            paddingVertical: 20,
            alignItems: 'center',
            position: 'relative',
            maxWidth: 600,
            alignSelf: 'center'
        },
        close: {
            position: 'absolute',
            top: 24,
            right: 24,
            zIndex: 2
        },
        modalContent: {
            paddingHorizontal: 40,
            marginTop: 18
        },
        description: {
            marginBottom: 20
        },
        buttonRow: {
            marginHorizontal: 0,
            marginVertical: 12
        }
    });

    const onClosePress = (): void => {
        setModalVisible(false);

        if (props.onClose) {
            props.onClose();
        }
    };

    const renderButtons = (): JSX.Element[] => {
        return (
            buttons?.map((btn, idx) => {
                const { type: btnType, text, disabled, onPress } = btn;

                if (btnType === 'Primary' || btnType === 'Secondary') {
                    const Button = LatButton[btnType];
                    return (
                        <LatRow key={idx} style={defaultStyles.buttonRow}>
                            <LatCol>
                                <Button
                                    onPress={onPress}
                                    title={text}
                                    disabled={disabled}
                                    small={btnType === 'Secondary'}
                                />
                            </LatCol>
                        </LatRow>
                    );
                } else if (btnType === 'TextLink') {
                    return (
                        <LatRow key={idx} justifyContent="center" style={defaultStyles.buttonRow}>
                            <TextLinkButton
                                onPress={(e): void => {
                                    if (!disabled && onPress) {
                                        onPress(e);
                                    }
                                }}
                            >
                                {text}
                            </TextLinkButton>
                        </LatRow>
                    );
                } else {
                    throw Error(`Unknown button type ${btnType}`);
                }
            }) ?? []
        );
    };

    useEffect(() => {
        if (props.visible !== modalVisible) {
            setModalVisible(props.visible ?? false);
        }
    }, [props.visible]);

    return (
        <Modal animationType="fade" transparent={true} visible={modalVisible || false}>
            <Pressable
                style={defaultStyles.backdrop}
                onPress={() => {
                    if (tapOutsideToDismiss) {
                        onClosePress();
                    }
                }}
            />
            <SafeAreaView>
                <View style={[defaultStyles.modalView, style]} {...otherProps}>
                    <Pressable style={defaultStyles.close} onPress={onClosePress}>
                        <Close />
                    </Pressable>
                    <LatRow style={defaultStyles.modalContent}>
                        <LatCol>
                            {contentBefore}

                            {title && (
                                <LatRow>
                                    <LatCol>
                                        <Heading3 textAlign="center">{title}</Heading3>
                                    </LatCol>
                                </LatRow>
                            )}

                            {description && (
                                <LatRow>
                                    <LatCol>
                                        <Body
                                            fontWeight="semibold"
                                            textAlign="center"
                                            style={defaultStyles.description}
                                        >
                                            {description}
                                        </Body>
                                    </LatCol>
                                </LatRow>
                            )}

                            {children}

                            {renderButtons()}
                        </LatCol>
                    </LatRow>
                </View>
            </SafeAreaView>
        </Modal>
    );
};

export default LatModal;
