import { Ionicons } from '@expo/vector-icons';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import * as SecureStore from 'expo-secure-store';
import * as SplashScreen from 'expo-splash-screen';
import * as React from 'react';
import { Image } from 'react-native';
import SecureStoreKeys from '../constants/SecureStoreKeys';

export const useCachedResources = (): boolean => {
    const [isLoadingComplete, setLoadingComplete] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            SplashScreen.hideAsync();
        }, 1000);
    }, [isLoadingComplete]);

    function cacheImages(images: any[]) {
        return images.map((image) => {
            if (typeof image === 'string') {
                return Image.prefetch(image);
            } else {
                return Asset.fromModule(image)
                    .downloadAsync()
                    .then((asset: Asset) => !!asset);
            }
        });
    }

    // Load any resources or data that we need prior to rendering the app
    React.useEffect(() => {
        SplashScreen.preventAutoHideAsync();
        const loadResourcesAndDataAsync = async (): Promise<void> => {
            try {
                const imageAssets = cacheImages([
                    require('../assets/images/accountcard/checking_small.png'),
                    require('../assets/images/accountcard/reserve_small.png'),
                    require('../assets/images/splash.png')
                ]);

                await Promise.all(imageAssets);

                // Load fonts
                await Font.loadAsync({
                    ...Ionicons.font,
                    'ChronicleDisplay-Semibold': require('../assets/fonts/ChronicleDisp-Semibold.otf'),
                    'ChronicleDisplay-Roman': require('../assets/fonts/ChronicleDisp-Roman.otf'),
                    'ChronicleDisplay-LightItalic': require('../assets/fonts/ChronicleDisp-LightItalic.otf'),
                    'ChronicleDisplay-Light': require('../assets/fonts/ChronicleDisp-Light.otf'),
                    'ChronicleDisplay-Italic': require('../assets/fonts/ChronicleDisp-Italic.otf'),
                    'ChronicleDisplay-Bold': require('../assets/fonts/ChronicleDisp-Bold.otf'),
                    'ChronicleDisplay-BlackItalic': require('../assets/fonts/ChronicleDisp-BlackItalic.otf'),
                    'ChronicleDisplay-Black': require('../assets/fonts/ChronicleDisp-Black.otf'),
                    ChronicleDisplay: require('../assets/fonts/Chronicle-Display.otf'),
                    'ProximaNova-Black': require('../assets/fonts/ProximaNova-Black.otf'),
                    'ProximaNova-Bold': require('../assets/fonts/ProximaNova-Bold.otf'),
                    'ProximaNova-BoldItalic': require('../assets/fonts/ProximaNova-BoldItalic.otf'),
                    'ProximaNova-Light': require('../assets/fonts/ProximaNova-Light.otf'),
                    'ProximaNova-LightItalic': require('../assets/fonts/ProximaNova-LightItalic.otf'),
                    'ProximaNova-Regular': require('../assets/fonts/ProximaNova-Regular.otf'),
                    'ProximaNova-RegularItalic': require('../assets/fonts/ProximaNova-RegularItalic.otf'),
                    'ProximaNova-Semibold': require('../assets/fonts/ProximaNova-Semibold.otf'),
                    'ProximaNova-SemiboldItalic': require('../assets/fonts/ProximaNova-SemiboldItalic.otf')
                });

                // Set hero background index (0-5)
                const index = Math.floor(Math.random() * 6);
                await SecureStore.setItemAsync(
                    SecureStoreKeys.HERO_BACKGROUND_INDEX,
                    index.toString()
                );
            } catch (e) {
                // We might want to provide this error information to an error reporting service
                console.warn(e);
            } finally {
                setLoadingComplete(true);
            }
        };

        loadResourcesAndDataAsync();
    }, []);

    return isLoadingComplete;
};

export default useCachedResources;
