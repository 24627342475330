import * as Linking from 'expo-linking';
import React from 'react';
import { StyleSheet } from 'react-native';
import LatModal, { LatModalProps } from './LatModal';
import { TextLinkButton } from './StyledText';

const ContactSupportModal = (props: LatModalProps): JSX.Element => {
    const {
        title, // eslint-disable-line
        description, // eslint-disable-line
        children, // eslint-disable-line
        ...otherProps
    } = props;

    const defaultStyles = StyleSheet.create({
        email: {
            marginTop: -8,
            marginBottom: 12
        }
    });

    const onPressTextLink = (): void => {
        Linking.openURL('mailto:support@getlatitude.com');
    };

    return (
        <LatModal
            title="Contact Us"
            description="Need help? Got a problem? Just want to Chat? No Problem! Our Customer Support Team is available 24 hours a day by email."
            {...otherProps}
        >
            <TextLinkButton
                onPress={onPressTextLink}
                textAlign="center"
                style={defaultStyles.email}
            >
                Support@getlatitude.com
            </TextLinkButton>
        </LatModal>
    );
};

export default ContactSupportModal;
