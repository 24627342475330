import { Platform } from 'react-native';
import {
    getUnhandledPromiseRejectionTracker,
    setUnhandledPromiseRejectionTracker
} from 'react-native-promise-rejection-utils';
import * as Sentry from 'sentry-expo';

const prevTracker = getUnhandledPromiseRejectionTracker();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const captureException = async (error: any, captureContext?: any) => {
    if (Platform.OS === 'web') {
        Sentry.Browser.captureException(error, captureContext);
    } else {
        Sentry.Native.captureException(error, captureContext);
    }
};

const initUnhandledPromiseRejectionTracker = (): void => {
    setUnhandledPromiseRejectionTracker((id: number, error: any) => {
        error.name = `Possible Unhandled Promise Rejection: ${error.message}`;

        if (prevTracker !== undefined) {
            prevTracker(id, error);
        }
    });
};

export default initUnhandledPromiseRejectionTracker;
