import { mapToMarket, Market } from '@latitude/models';
import MarketService from '@latitude/services/MarketService';
import { InitSlice } from './slice';

export interface MarketState {
    isLoading: boolean;
    markets: Market[];
    init: () => Promise<void>;
}

export const initMarketSlice: InitSlice<MarketState> = (set, get) => ({
    isLoading: false,
    markets: [],
    init: async () => {
        try {
            set({ isLoading: true });

            const data = await MarketService.getMarkets();
            const mappedMarkets = data.data.map(mapToMarket) as Market[];
            mappedMarkets.sort((a, b) => a.displayIndex - b.displayIndex);

            set({ isLoading: false, markets: mappedMarkets });
        } finally {
            set({ isLoading: false });
        }
    }
});
