import { Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

export const window = {
    width,
    height
};

export const screen = {
    ...Dimensions.get('screen')
};

export const isSmallDevice = width <= 375;
export const navHeight = isSmallDevice ? 84 : 126;
export const tabHeight = 56;
export const headerOffset = 44;

export default {
    window,
    isSmallDevice,
    navHeight,
    headerOffset,
    screen
};
