import LatButton from '@latitude/components/LatButton';
import { LatCol } from '@latitude/components/LatCol';
import { LatRow } from '@latitude/components/LatRow';
import { LatScreen } from '@latitude/components/LatScreen';
import MembershipCard from '@latitude/components/MembershipCard';
import OnboardingBg from '@latitude/components/OnboardingBg';
import { Body, Heading2 } from '@latitude/components/StyledText';
import { useThemeColor } from '@latitude/components/Themed';
import { useOnboarding } from '@latitude/contexts/Onboarding';
import { useSubscription } from '@latitude/state';
import { RootStackParamList } from '@latitude/types';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useEffect, useMemo } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

interface NavigationProps {
    navigation: StackNavigationProp<RootStackParamList, 'Membership'>;
}

const MembershipScreen = ({ navigation }: NavigationProps): JSX.Element => {
    const { setCurrentScreen, setOnboardingNotFinished } = useOnboarding();
    const { plans } = useSubscription();

    const plan = useMemo(() => {
        return plans.find((p) => p.plan_id === 2);
    }, [plans]);

    const background = useThemeColor('background');

    const styles = StyleSheet.create({
        scrollViewContentContainerStyle: {
            flexGrow: 1
        },
        heading: {
            marginTop: 24,
            marginBottom: 36
        },
        title: {
            marginBottom: 8
        },
        body: {},
        nextButtonRow: {
            marginTop: 21,
            marginBottom: 40
        },
        planContainer: {
            backgroundColor: background,
            paddingHorizontal: 24,
            paddingTop: 16,
            paddingBottom: 16
        },
        planTitle: {
            paddingVertical: 8
        },
        planText: {},
        strikePlan: {
            textDecorationLine: 'line-through',
            textDecorationStyle: 'solid',
            marginRight: 4
        }
    });

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            setCurrentScreen('Subscription');
        });

        return unsubscribe;
    }, [navigation]);

    useEffect(() => {
        setOnboardingNotFinished(true);
    }, []);

    return (
        <LatScreen>
            <OnboardingBg />
            <ScrollView
                showsVerticalScrollIndicator={false}
                keyboardShouldPersistTaps="handled"
                contentContainerStyle={styles.scrollViewContentContainerStyle}
                bounces={false}
            >
                <View style={{ flex: 1, alignItems: 'center' }}>
                    <LatRow style={styles.heading}>
                        <LatCol>
                            <Heading2 style={styles.title}>Membership Plan</Heading2>
                            <Body style={styles.body} fontWeight="semibold">
                                Subject to Latitude&apos;s policies and agreements, as well as the
                                disclosures on our site&apos;s &apos;Membership&apos; page, below is
                                a summary of the perks, rewards and philanthropy we offer on an
                                annual basis:
                            </Body>
                        </LatCol>
                    </LatRow>
                    {plan && <MembershipCard plan={plan} />}
                </View>
                <LatRow>
                    <LatCol style={styles.nextButtonRow}>
                        <LatButton.Primary
                            title="Return To Request Invite"
                            onPress={() => {
                                navigation.goBack();
                            }}
                        />
                    </LatCol>
                </LatRow>
            </ScrollView>
        </LatScreen>
    );
};

export default MembershipScreen;
