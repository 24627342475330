import React, { useState } from 'react';
import {
    GestureResponderEvent,
    LayoutChangeEvent,
    NativeSyntheticEvent,
    StyleProp,
    StyleSheet,
    TextInput,
    TextInputFocusEventData,
    TextStyle
} from 'react-native';
import { Body, Label, TextLinkButton } from './StyledText';
import { useThemeColor } from './Themed';

export type LatInputProps = TextInput['props'] & {
    textInputRef?:
        | string
        | ((instance: TextInput | null) => void)
        | React.RefObject<TextInput>
        | null;
    focusedStyle?: StyleProp<TextStyle>;
    prefix?: string;
    showRightCta?: boolean;
    rightCtaText?: string;
    error?: boolean;
    errorText?: string;
    hidePlaceholderOnFocus?: boolean;
    onRightCtaPress?: (event: GestureResponderEvent) => void;
};

export const LatInput = (props: LatInputProps): JSX.Element => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [prefixWidth, setPrefixWidth] = useState<number>(0);
    const [rightCtaWidth, setRightCtaWidth] = useState<number>(0);
    const {
        style,
        focusedStyle,
        onFocus,
        onBlur,
        error,
        placeholder,
        hidePlaceholderOnFocus,
        keyboardAppearance = 'dark',
        ...otherProps
    } = props;

    const background = useThemeColor('background');
    const regular = useThemeColor('regular');
    const light = useThemeColor('light');
    const primary = useThemeColor('primary');
    const errorColor = useThemeColor('error');

    const defaultStyles = StyleSheet.create({
        latInput: {
            width: '100%',
            paddingHorizontal: 24,
            paddingVertical: 12,
            backgroundColor: background,
            fontFamily: 'ProximaNova-Regular',
            fontSize: 16,
            color: regular,
            borderRadius: 8,
            height: 48
        },
        latInputFocused: {
            borderWidth: 1,
            borderColor: primary
        },
        prefix: {
            position: 'absolute',
            left: 24,
            top: 12
        },
        rightCta: {
            position: 'absolute',
            right: 32,
            top: 11
        },
        latInputError: {
            borderWidth: 1,
            borderColor: errorColor
        },
        errorText: {
            marginTop: 4
        },
        disabled: {
            opacity: 0.5
        }
    });

    const onTextInputFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>): void => {
        setIsFocused(true);
        if (onFocus) {
            onFocus(e);
        }
    };

    const onTextInputBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>): void => {
        setIsFocused(false);
        if (onBlur) {
            onBlur(e);
        }
    };

    const onPrefixLayout = (event: LayoutChangeEvent): void => {
        setPrefixWidth(event.nativeEvent.layout.width ?? 0);
    };

    const onRightCtaTextLayout = (event: LayoutChangeEvent): void => {
        setRightCtaWidth(event.nativeEvent.layout.width ?? 0);
    };

    return (
        <>
            <TextInput
                ref={props.textInputRef}
                style={[
                    defaultStyles.latInput,
                    props.prefix && { paddingLeft: 20 + prefixWidth },
                    props.showRightCta && { paddingRight: 48 + rightCtaWidth },
                    style,
                    isFocused && defaultStyles.latInputFocused,
                    isFocused && focusedStyle,
                    error && defaultStyles.latInputError,
                    props.editable === false && defaultStyles.disabled
                ]}
                selectionColor={regular}
                placeholderTextColor={light}
                onFocus={onTextInputFocus}
                onBlur={onTextInputBlur}
                keyboardAppearance={keyboardAppearance}
                placeholder={hidePlaceholderOnFocus && isFocused ? '' : placeholder}
                {...otherProps}
            />
            {props.showRightCta && (
                <TextLinkButton
                    onPress={props.onRightCtaPress}
                    style={defaultStyles.rightCta}
                    onLayout={onRightCtaTextLayout}
                >
                    {props.rightCtaText}
                </TextLinkButton>
            )}
            {!!props.prefix && (
                <Body style={defaultStyles.prefix} onLayout={onPrefixLayout}>
                    {props.prefix}
                </Body>
            )}
            {!!props.errorText && (
                <Label style={defaultStyles.errorText} colorScheme="error">
                    {props.errorText}
                </Label>
            )}
        </>
    );
};
