import api from '../utils/api';

export const getVendors = async (params?: Record<string, any>): Promise<any> => {
    return await api
        .get('/vendor/', {
            params
        })
        .then((response) => response.data);
};

export default {
    getVendors
};
