import { Charity, mapToCharity } from '@latitude/models/Charity';
import CharityService from '@latitude/services/CharityService';
import { InitSlice } from './slice';

export interface CharityState {
    isLoading: boolean;
    charities: Charity[];
    refreshCharities: (market_id: number) => Promise<any>;
}

export const initCharitySlice: InitSlice<CharityState> = (set, get) => ({
    isLoading: false,
    charities: [],
    refreshCharities: async (market_id: number) => {
        try {
            set({ isLoading: true });

            const charityResponse = await CharityService.getCharities({
                filter: {
                    market_id
                }
            });

            if (charityResponse.success) {
                const mappedCharities = charityResponse.data.map(mapToCharity) as Charity[];
                set({ charities: mappedCharities });
            }
        } finally {
            set({ isLoading: false });
        }
    }
});
