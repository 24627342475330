export interface Charity {
    description?: string;
    displayIndex: number;
    charityId: number;
    name: string;
    bannerUrl: string;
    logoUrl: string;
    website?: string;
    marketId: number;
}

export const mapToCharity = (data: any): Charity | undefined => {
    if (!data) {
        return undefined;
    }

    return {
        description: data.description,
        displayIndex: data.display_index,
        charityId: data.charity_id,
        name: data.name,
        bannerUrl: data.banner_url,
        logoUrl: data.logo_url,
        website: data.website,
        marketId: data.market_id
    };
};

export interface CharityTotal {
    charity_id: number;
    name: string;
    banner_url: string;
    total: number;
}
