import React from 'react';
import {
    ColorValue,
    Image,
    Pressable,
    PressableProps,
    StyleProp,
    StyleSheet,
    View,
    ViewStyle
} from 'react-native';
import { Body, BodyProps } from './StyledText';
import { useThemeColor } from './Themed';

const hand = require('../assets/images/hand.png');

interface LatButtonStyle extends ViewStyle {
    color?: ColorValue;
}

interface LatButtonProps extends PressableProps {
    title: string;
    fontWeight?: BodyProps['fontWeight'];
    pressedFontWeight?: BodyProps['fontWeight'];
    style?: StyleProp<LatButtonStyle>;
    pressedStyle?: StyleProp<LatButtonStyle>;
    disabledStyle?: StyleProp<LatButtonStyle>;
    small?: boolean;
    horizontalPadding?: number;
    verticalPadding?: number;
    forceShowPressedState?: boolean;
    icon?: JSX.Element;
    pressedIcon?: JSX.Element;
}

const BaseButton = (props: LatButtonProps): JSX.Element => {
    const {
        style,
        pressedStyle,
        disabledStyle,
        fontWeight,
        pressedFontWeight,
        small,
        horizontalPadding,
        verticalPadding,
        forceShowPressedState,
        icon,
        pressedIcon,
        ...otherProps
    } = props;

    const flattenedStyle = style ? StyleSheet.flatten(style) : null;
    const flattenedPressedStyle = pressedStyle ? StyleSheet.flatten(pressedStyle) : null;

    let paddingVertical = small ? 8 : 12;
    let paddingHorizontal = small ? 31 : 38;

    if (horizontalPadding !== undefined) {
        paddingHorizontal = horizontalPadding;
    }
    if (verticalPadding !== undefined) {
        paddingVertical = verticalPadding;
    }

    const defaultStyles = StyleSheet.create({
        container: {
            // flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'center',
            width: StyleSheet.flatten(style).width,
            maxWidth: StyleSheet.flatten(style).maxWidth
        },
        pressable: {
            flex: 1,
            borderRadius: 50,
            paddingVertical,
            paddingHorizontal,
            backgroundColor: '#ffffff',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
        },
        text: {
            color: flattenedStyle?.color ? flattenedStyle.color : useThemeColor('regular'),
            textAlign: 'center'
        },
        textPressed: {
            color: flattenedPressedStyle?.color
                ? flattenedPressedStyle.color
                : useThemeColor('regular')
        }
    });

    return (
        <View style={defaultStyles.container}>
            <Pressable
                style={({ pressed }): StyleProp<LatButtonStyle> => [
                    defaultStyles.pressable,
                    style,
                    pressed && pressedStyle,
                    props.disabled && !forceShowPressedState && disabledStyle,
                    forceShowPressedState && pressedStyle
                ]}
                {...otherProps}
            >
                {({ pressed }): JSX.Element => (
                    <>
                        {!pressed && icon}
                        {pressed && pressedIcon}
                        <Body
                            fontWeight={
                                (pressed && pressedFontWeight) || (fontWeight ?? 'semibold')
                            }
                            style={[
                                defaultStyles.text,
                                pressed && pressedStyle && defaultStyles.textPressed,
                                forceShowPressedState && pressedStyle && defaultStyles.textPressed
                            ]}
                            numberOfLines={1}
                            adjustsFontSizeToFit
                        >
                            {props.title}
                        </Body>
                    </>
                )}
            </Pressable>
        </View>
    );
};

const LatButton = {
    Primary: (props: LatButtonProps): JSX.Element => {
        const { style, pressedStyle, disabledStyle, ...otherProps } = props;

        const primary = useThemeColor('primary');
        const primaryContrast = useThemeColor('primaryContrast');

        const defaultStyles = StyleSheet.create({
            default: {
                backgroundColor: primary
            },
            disabled: {
                opacity: 0.5
            },
            pressed: {
                backgroundColor: primaryContrast
            }
        });

        return (
            <BaseButton
                style={[defaultStyles.default, style]}
                pressedStyle={[defaultStyles.pressed, pressedStyle]}
                disabledStyle={[defaultStyles.disabled, disabledStyle]}
                {...otherProps}
            />
        );
    },
    Secondary: (props: LatButtonProps): JSX.Element => {
        const { style, pressedStyle, disabledStyle, ...otherProps } = props;

        const secondary = useThemeColor('secondary');
        const dark = useThemeColor('dark');

        const defaultStyles = StyleSheet.create({
            default: {
                backgroundColor: 'transparent',
                color: secondary,
                borderWidth: 1,
                borderColor: secondary
            },
            disabled: {
                opacity: 0.5
            },
            pressed: {
                backgroundColor: secondary,
                color: dark
            }
        });

        return (
            <BaseButton
                fontWeight="bold"
                style={[defaultStyles.default, style]}
                pressedStyle={[defaultStyles.pressed, pressedStyle]}
                disabledStyle={[defaultStyles.disabled, disabledStyle]}
                {...otherProps}
            />
        );
    },
    More: (props: LatButtonProps): JSX.Element => {
        const { style, pressedStyle, disabledStyle, ...otherProps } = props;

        const secondary = useThemeColor('secondary');
        const dark = useThemeColor('dark');

        const defaultStyles = StyleSheet.create({
            default: {
                backgroundColor: '#302a2c',
                color: secondary
            },
            disabled: {
                opacity: 0.5
            },
            pressed: {
                backgroundColor: secondary,
                color: dark
            },
            icon: {
                width: 24,
                height: 24,
                marginRight: 8
            },
            pressedIcon: {
                tintColor: dark
            }
        });

        return (
            <BaseButton
                icon={<Image style={defaultStyles.icon} source={hand} />}
                pressedIcon={
                    <Image style={[defaultStyles.icon, defaultStyles.pressedIcon]} source={hand} />
                }
                fontWeight="bold"
                style={[defaultStyles.default, style]}
                pressedStyle={[defaultStyles.pressed, pressedStyle]}
                disabledStyle={[defaultStyles.disabled, disabledStyle]}
                {...otherProps}
            />
        );
    },
    Tertiary: (props: LatButtonProps): JSX.Element => {
        const { style, pressedStyle, disabledStyle, ...otherProps } = props;

        const primaryLight = useThemeColor('primaryLight');
        const borders = useThemeColor('borders');

        const defaultStyles = StyleSheet.create({
            default: {
                backgroundColor: borders,
                color: primaryLight
            },
            disabled: {
                opacity: 0.5
            },
            pressed: {
                backgroundColor: primaryLight,
                color: borders
            }
        });

        return (
            <BaseButton
                fontWeight="bold"
                style={[defaultStyles.default, style]}
                pressedStyle={[defaultStyles.pressed, pressedStyle]}
                disabledStyle={[defaultStyles.disabled, disabledStyle]}
                {...otherProps}
            />
        );
    },
    SelectorButton: (props: LatButtonProps): JSX.Element => {
        const { style, pressedStyle, disabledStyle, ...otherProps } = props;

        const primaryLight = useThemeColor('primaryLight');
        const borders = useThemeColor('borders');
        const regular = useThemeColor('regular');

        const defaultStyles = StyleSheet.create({
            default: {
                backgroundColor: borders,
                color: regular,
                borderColor: 'transparent',
                borderWidth: 1
            },
            disabled: {
                opacity: 0.5
            },
            pressed: {
                color: regular,
                backgroundColor: borders,
                borderColor: primaryLight,
                borderWidth: 1
            }
        });

        return (
            <BaseButton
                fontWeight="regular"
                style={[defaultStyles.default, style]}
                pressedStyle={[defaultStyles.pressed, pressedStyle]}
                disabledStyle={[defaultStyles.disabled, disabledStyle]}
                horizontalPadding={0}
                small
                {...otherProps}
            />
        );
    }
};

export default LatButton;
