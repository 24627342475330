import React from 'react';
import { View, ViewProps } from 'react-native';
import { CircleSnail } from 'react-native-progress';

type LoaderProps = ViewProps & {
    size?: 'small' | 'medium' | 'large';
};

const Loader = (props: LoaderProps): JSX.Element => {
    const { size = 'medium', style, ...rest } = props;

    let pixels = 24;

    if (size === 'medium') {
        pixels = 48;
    } else if (size === 'large') {
        pixels = 80;
    }

    return (
        <View style={[{ flexDirection: 'column', alignItems: 'center' }, style]} {...rest}>
            <CircleSnail
                thickness={size === 'small' ? 2 : 4}
                size={pixels}
                strokeCap="round"
                color={['#b38b7d']}
                indeterminate={true}
            />
        </View>
    );
};

export default Loader;
