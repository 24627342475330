export interface PromoCode {
    active: boolean;
    code: string;
    dateCreated: Date;
    dateUpdated: Date;
    discountedCents: number;
}

export const mapToPromoCode = (data: any): PromoCode | undefined => {
    if (!data) {
        return undefined;
    }

    return {
        active: data.active,
        code: data.code,
        dateCreated: new Date(data.date_created),
        dateUpdated: new Date(data.date_updated),
        discountedCents: data.discounted_cents
    };
};
