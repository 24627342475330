import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const Upload = (props: SvgProps) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512.056 512.056"
        style={{
            enableBackground: 'new 0 0 512.056 512.056'
        }}
        xmlSpace="preserve"
        {...props}
    >
        <Path d="M426.635 188.224C402.969 93.946 307.358 36.704 213.08 60.37 139.404 78.865 85.907 142.542 80.395 218.303 28.082 226.93-7.333 276.331 1.294 328.644c7.669 46.507 47.967 80.566 95.101 80.379h80v-32h-80c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64 8.837 0 16-7.163 16-16-.08-79.529 64.327-144.065 143.856-144.144 68.844-.069 128.107 48.601 141.424 116.144a16 16 0 0 0 13.6 12.8c43.742 6.229 74.151 46.738 67.923 90.479-5.593 39.278-39.129 68.523-78.803 68.721h-64v32h64c61.856-.187 111.848-50.483 111.66-112.339-.156-51.49-35.4-96.241-85.42-108.46z" />
        <Path d="m245.035 253.664-64 64 22.56 22.56 36.8-36.64v153.44h32v-153.44l36.64 36.64 22.56-22.56-64-64c-6.241-6.204-16.319-6.204-22.56 0z" />
    </Svg>
);

export default Upload;
