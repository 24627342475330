import * as React from 'react';
import { StyleSheet, View } from 'react-native';

export type LatColProps = View['props'] & {
    size?: 1 | 2 | 3 | 4;
};

export const LatCol = (props: LatColProps): JSX.Element => {
    const { size, style, ...otherProps } = props;

    const flattenedStyle = StyleSheet.flatten(props.style);

    const defaultStyles = StyleSheet.create({
        latCol: {
            paddingHorizontal: 8,
            flexDirection: 'column',
            flex: size ? size / 4 : flattenedStyle && flattenedStyle.width ? 0 : 1
        }
    });

    return <View style={[defaultStyles.latCol, style]} {...otherProps} />;
};
