import config from '@latitude/config/config';

declare global {
    interface Window {
        analytics: any;
    }
}

const initialize = () => {
    if (window.analytics) {
        window.analytics._writeKey = config.segment.web;
        window.analytics.load(config.segment.web);
    }
};

initialize();

export function identify(userId: string, traits: Record<string, unknown> = {}): void {
    window.analytics.identify(userId, traits);
}

export function track(name: string, properties: Record<string, unknown> = {}): void {
    window.analytics.track(name.toLowerCase(), properties);
}

export function screenLoaded(
    category: string,
    name: string,
    properties: Record<string, unknown> = {}
): void {
    window.analytics.page(name.toLowerCase(), {
        category: category.toLowerCase(),
        ...properties
    });
}
