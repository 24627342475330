import {
    DebitCard,
    mapToDebitCard,
    mapToSyntheticAccount,
    SyntheticAccount,
    SyntheticAccountCategory,
    SyntheticAccountStatus
} from '@latitude/models';
import AccountService from '@latitude/services/AccountService';
import DebitCardService from '@latitude/services/DebitCardService';
import moment from 'moment';
import { InitSlice } from './slice';

export interface AccountsState {
    isLoading: boolean;
    accounts: SyntheticAccount[];
    liabilityAccounts: SyntheticAccount[];
    externalAccounts: SyntheticAccount[];
    refetchAccounts: () => Promise<any>;
    reset: () => void;
}

const initialState = {
    isLoading: false,
    accounts: [],
    liabilityAccounts: [],
    externalAccounts: []
};

export const initAccountsSlice: InitSlice<AccountsState> = (set, get) => ({
    ...initialState,
    reset: () => {
        set(initialState);
        console.log({ accountState: initialState });
    },
    refetchAccounts: async () => {
        try {
            set({ isLoading: true });
            const accountsResponse = await AccountService.getAccounts();
            const debitCardsResponse = await DebitCardService.getDebitCards();
            const debitCards: DebitCard[] = debitCardsResponse.data.data.map(mapToDebitCard);
            debitCards.sort((a, b) => moment(b.issuedOn).diff(moment(a.issuedOn)));

            const accounts: SyntheticAccount[] = (
                accountsResponse.data.data.map(mapToSyntheticAccount) as SyntheticAccount[]
            ).map((x) => ({
                ...x,
                debitCard: debitCards.find((d) => d.syntheticAccountUid === x.uid)
            }));

            const liabilityAccounts = accounts
                .filter((x) => x.liability === true && x.status === SyntheticAccountStatus.Active)
                .sort((x, y) =>
                    // Sort primary first
                    x.isPrimary === y.isPrimary ? 0 : x.isPrimary ? -1 : 1
                );

            const externalAccounts = accounts.filter((x) =>
                [
                    SyntheticAccountCategory.External,
                    SyntheticAccountCategory.PlaidExternal
                ].includes(x.syntheticAccountCategory)
            );

            set({ accounts, liabilityAccounts, externalAccounts, isLoading: false });
        } finally {
            set({ isLoading: false });
        }
    }
});
