import api from '../utils/api';

export type CustomerAddress = {
    street1: string;
    street2?: string;
    city: string;
    state: string;
    postal_code: string;
};

export type CustomerDetails = {
    first_name: string;
    middle_name?: string;
    last_name: string;
    phone: string;
    dob: string;
    ssn: string;
    address: CustomerAddress;
};

export const getCustomer = async (): Promise<any> => {
    return await api.get('/customer').then((response) => response.data);
};

export const updateCustomer = async (details: CustomerDetails): Promise<any> => {
    return await api.put('/customer/update', { details }).then((response) => response.data);
};

export const updateAddress = async (address: CustomerAddress): Promise<any> => {
    return await api
        .put('/customer/update_address', { details: { address } })
        .then((response) => response.data);
};

export const requestIdentityVerification = async (): Promise<any> => {
    return await api.put('/customer/identity_verification').then((response) => response.data);
};

export const completeOnboarding = async (): Promise<any> => {
    return await api.post('/customer/complete_onboarding').then((response) => response.data);
};

export const changePassword = async (oldPassword: string, newPassword: string): Promise<any> => {
    return await api
        .put('/customer/update_password', { oldPassword, newPassword })
        .then((response) => response.data);
};

export const getZendeskToken = async (): Promise<any> => {
    return await api.get('/customer/zendesk_token').then((response) => response.data);
};

export const updateAutofunding = async (params: {
    refillThresholdCents: string | number | null;
    refillAmountCents?: string | number;
}): Promise<any> => {
    return await api.put('/customer/update_autofunding', params).then((response) => response.data);
};

export const updateEmail = async (email: string): Promise<any> => {
    return await api.put('/customer/update_email', { email }).then((response) => response.data);
};

export const updatePhone = async (phone: string): Promise<any> => {
    return await api.put('/customer/update_phone', { phone }).then((response) => response.data);
};

export const closeAccount = async (): Promise<any> => {
    return await api.post('/customer/close_account', {}).then((response) => response.data);
};

export const forgotPassword = async (email: string): Promise<any> => {
    return await api.post('/auth/forgot-password', { email }).then((response) => response.data);
};

export const getOobCode = async (
    token: string,
    mode: 'challenge' | 'associate' = 'challenge',
    phoneNumber?: string
): Promise<any> => {
    if (mode === 'challenge') {
        return await api.post('/auth/challenge', { mfaToken: token }).then((response) => {
            return response.data;
        });
    } else if (mode === 'associate') {
        return await api
            .post('/auth/associate-mfa', {
                mfaToken: token,
                phoneNumber: phoneNumber
            })
            .then((response) => {
                return response.data;
            });
    }
};

export const createUser = async (
    email: string,
    password: string,
    inviteCode?: string,
    promoCode?: string
): Promise<any> => {
    const params = {
        email,
        password,
        inviteCode,
        promoCode
    };
    return await api.post('/customer/create', params).then((response) => response.data);
};

export const requestInviteCode = async (params: {
    name: string;
    email: string;
    market_id: number;
    about: string;
}): Promise<any> => {
    return await api.post('/invitation', params).then((response) => response.data);
};

export const completeMfa = async (
    codeNumber: string,
    mfaToken: string,
    oobCode: string,
    rememberEmail: boolean
): Promise<any> => {
    return await api
        .post('/auth/token', {
            mfaToken: mfaToken,
            oobCode: oobCode,
            bindingCode: codeNumber,
            rememberEmail: rememberEmail
        })
        .then((response) => {
            return response.data;
        })
        .catch(() => {
            // if the request did not return.
            return {
                success: false,
                status: 500
            };
        });
};

export default {
    getCustomer,
    updateCustomer,
    updateAddress,
    updateEmail,
    updatePhone,
    requestIdentityVerification,
    completeOnboarding,
    changePassword,
    getZendeskToken,
    closeAccount,
    getOobCode,
    createUser,
    requestInviteCode,
    completeMfa,
    forgotPassword
};
