import LatButton from '@latitude/components/LatButton';
import { StackNavigationProp } from '@react-navigation/stack';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useMemo, useState } from 'react';
import { ImageBackground, Platform, Pressable, StyleSheet, View } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import ContactSupportModal from '../components/ContactSupportModal';
import { LatCol } from '../components/LatCol';
import { LatLogo } from '../components/LatLogo';
import { LatRow } from '../components/LatRow';
import { Body, Heading1Large, Label, TextLinkButton, Title } from '../components/StyledText';
import { useThemeColor } from '../components/Themed';
import { Close } from '../svg';
import { RootStackParamList } from '../types';

const inviteBackground = require('../assets/images/invite_pending_bg.png');
interface InvitePendingScreenProps {
    navigation: StackNavigationProp<RootStackParamList, 'InvitePending'>;
}

const InvitePendingScreen = ({ navigation }: InvitePendingScreenProps): JSX.Element => {
    const [contactModalVisible, setContactModalVisible] = useState<boolean>(false);

    const isDesktop = useMediaQuery({ minWidth: 992 });
    const dark = useThemeColor('dark');
    const light = useThemeColor('light');

    const styles = StyleSheet.create({
        container: {
            backgroundColor: dark,
            flex: 1
        },
        logoRow: {
            position: 'absolute',
            top: 48,
            // marginTop: 16,
            marginBottom: 116,
            marginLeft: 24,
            zIndex: 1
        },
        closePressable: {
            position: 'absolute',
            top: 13,
            right: 24
        },
        topBleed: {
            width: '100%',
            height: 115
        },
        bottomBleed: {
            width: '100%',
            height: 230
        },
        background: {
            flex: 1,
            width: '100%'
        },
        contentLower: {
            width: '100%',
            minHeight: 320,
            paddingHorizontal: 24,
            alignItems: isDesktop ? 'center' : 'flex-start',
            justifyContent: isDesktop ? 'center' : 'flex-start'
        },
        titleRow: {
            marginBottom: 24
        },
        instructionsRow: {
            marginBottom: 24
        },
        contactSupportRow: {
            marginBottom: 24,
            width: '100%'
        }
    });

    const onContactSupportPress = (): void => {
        setContactModalVisible(true);
    };

    const onContactSupportClose = (): void => {
        setContactModalVisible(false);
    };

    const onClosePress = (): void => {
        navigation.popToTop();
        navigation.navigate('Welcome');
    };

    const Content = useMemo(() => {
        if (isDesktop) {
            return (
                <>
                    <LatRow>
                        <LatCol style={{ alignItems: 'center' }}>
                            <Label
                                style={{
                                    letterSpacing: 3,
                                    textTransform: 'uppercase',
                                    fontSize: 14,
                                    color: light,
                                    marginTop: 48
                                }}
                            >
                                Get Latitude
                            </Label>
                            <Title style={{ maxWidth: 696, marginTop: 32 }} textAlign="center">
                                Invite Pending
                            </Title>
                            <Body style={{ fontSize: 18, marginTop: 24 }} textAlign="center">
                                Requests are reviewed by our committee on a rolling basis. We will
                                be in touch.
                            </Body>
                        </LatCol>
                    </LatRow>
                    <LatRow justifyContent="center" style={styles.contactSupportRow}>
                        <LatCol>
                            <Body style={{ fontSize: 18, marginBottom: 48 }} textAlign="center">
                                {'If you have any questions, please contact '}
                                <TextLinkButton
                                    textStyle={{ fontSize: 18 }}
                                    onPress={onContactSupportPress}
                                >
                                    Customer Support
                                </TextLinkButton>
                            </Body>
                        </LatCol>
                    </LatRow>
                    <LatRow
                        style={{
                            width: 400,
                            marginBottom: 48,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <LatCol>
                            <LatButton.Primary
                                title="RETURN TO LATITUDE HOME"
                                onPress={() => {
                                    window.location.href = 'https://www.getlatitude.com';
                                }}
                            />
                        </LatCol>
                    </LatRow>
                </>
            );
        } else {
            return (
                <>
                    <LatRow style={styles.titleRow}>
                        <LatCol>
                            <Heading1Large>Invite</Heading1Large>
                            <Heading1Large>Pending</Heading1Large>
                        </LatCol>
                    </LatRow>
                    <LatRow style={styles.instructionsRow}>
                        <LatCol>
                            <Body fontWeight="semibold">
                                Requests are reviewed by our committee on a rolling basis. We will
                                be in touch.
                            </Body>
                        </LatCol>
                    </LatRow>
                    <LatRow justifyContent="center" style={styles.contactSupportRow}>
                        <LatCol>
                            <Body fontWeight="semibold">
                                {'If you have any questions, please contact '}
                                <TextLinkButton onPress={onContactSupportPress}>
                                    Customer Support
                                </TextLinkButton>
                            </Body>
                        </LatCol>
                    </LatRow>
                </>
            );
        }
    }, [isDesktop]);

    return (
        <View style={styles.container}>
            <ImageBackground source={inviteBackground} style={[styles.background]}>
                {Platform.OS !== 'web' && (
                    <LatRow style={styles.logoRow}>
                        <LatCol size={1}>
                            <LatRow>
                                <LatLogo.Medium />
                            </LatRow>
                        </LatCol>
                        <LatCol>
                            <Pressable style={styles.closePressable} onPress={onClosePress}>
                                <Close width={32} height={32} />
                            </Pressable>
                        </LatCol>
                    </LatRow>
                )}
                <LinearGradient colors={[dark, 'transparent']} style={styles.topBleed} />
                <View style={{ flex: 1 }} />
                <LinearGradient colors={['transparent', dark]} style={styles.bottomBleed} />
            </ImageBackground>
            <View style={styles.contentLower}>
                <View style={{ flex: 1 }} />
                {Content}
            </View>
            <ContactSupportModal visible={contactModalVisible} onClose={onContactSupportClose} />
        </View>
    );
};

export default InvitePendingScreen;
