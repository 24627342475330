import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import * as Sentry from 'sentry-expo';
import { useThemeColor } from './components/Themed';
import config from './config/config';
import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import { useInit } from './state';
import initUnhandledPromiseRejectionTracker from './utils/errorHandling';
import './utils/match-media'; // Polyfills match media so react-responsive can be used in the app

Sentry.init({
    dsn: config.sentry.dsn,
    enableInExpoDevelopment:
        config.sentry.environment === 'development' && config.sentry.enableInDevelopment,
    debug: config.sentry.debug,
    environment: config.sentry.environment
});

initUnhandledPromiseRejectionTracker();

const App = (): any => {
    const isLoadingComplete = useCachedResources();
    const colorScheme = useColorScheme();
    const backgroundColor = useThemeColor('dark');

    // Initiates application state, loadings static data
    useInit();

    if (!isLoadingComplete) {
        return null;
    } else {
        return (
            <SafeAreaProvider style={{ backgroundColor }}>
                <Navigation colorScheme={colorScheme} />
                <StatusBar style="light" />
            </SafeAreaProvider>
        );
    }
};

export default App;
