import React from 'react';
import { StyleSheet } from 'react-native';
import { screen } from '../constants/Layout';
import { Palm2 } from '../svg';

export type OnboardingBgProps = {
    useBottomPositioning?: boolean;
};

const OnboardingBg = (props: OnboardingBgProps): JSX.Element => {
    const palm2Width = screen.width;
    const palm2Height = screen.height * 0.55;

    const defaultStyles = StyleSheet.create({
        palm2: {
            position: 'absolute',
            top: props.useBottomPositioning
                ? undefined
                : screen.height - palm2Height - palm2Height * 0.05,
            bottom: props.useBottomPositioning ? -(palm2Height * 0.1) : undefined,
            right: -(palm2Width * 0.315),
            opacity: 0.9,
            transform: [
                {
                    rotateY: '180deg'
                }
            ]
        }
    });
    return <Palm2 style={defaultStyles.palm2} width={palm2Width} height={palm2Height} />;
};

OnboardingBg.V2 = (): JSX.Element => {
    const palm2Width = screen.width;

    const defaultStyles = StyleSheet.create({
        palm2: {
            position: 'absolute',
            top: palm2Width * 0.8,
            right: -(palm2Width * 0.48),
            opacity: 0.9,
            transform: [
                {
                    rotate: '335deg'
                },
                {
                    rotateY: '180deg'
                },
                {
                    scaleX: -1
                }
            ]
        }
    });

    return <Palm2 style={defaultStyles.palm2} width={palm2Width} />;
};

export default OnboardingBg;
