import { AuthError } from '@latitude/models/Auth0';
import axios, { AxiosError } from 'axios';
import config from '../config/config';

export interface ErrorDetail {
    code?: number;
    title: string;
    detail?: string;
}
export interface ApiError {
    errors: ErrorDetail[];
    status?: number;
}

export interface ApiResponse<T> {
    data: T;
    success: boolean;
}

export function isApiError(data: unknown): data is ApiError {
    return (data as ApiError).errors !== undefined;
}

export function isAuthError(data: unknown): data is AuthError {
    return (data as AuthError).error !== undefined;
}

export function handleAxiosError(
    error: AxiosError,
    noInternetMessage = 'No internet connection.'
): ApiError {
    if (error.message === 'Network Error') {
        return { errors: [{ title: noInternetMessage }] } as ApiError;
    } else {
        return {
            errors: [{ title: 'An unknown error has occured' }],
            status: error.response?.status
        } as ApiError;
    }
}

const baseURL = config.api.baseUrl;
const client = axios.create({
    baseURL
});

// Axios retry is configured for 0 to allow specific endpoints to be enabled
// Exponential back-off retry delay between requests
// axiosRetry(axios, { retries: 0, retryDelay: axiosRetry.exponentialDelay });

export default client;
