import Colors from '@latitude/constants/Colors';
import { useAuth } from '@latitude/contexts/Auth';
import { Plan } from '@latitude/models/Subscription';
import * as WebBrowser from 'expo-web-browser';
import React from 'react';
import { Image, Platform, StyleSheet, Text, View, ViewProps } from 'react-native';
import { LatCol } from '../LatCol';
import { LatRow } from '../LatRow';
import { Body, Heading3, Label, TextLink } from '../StyledText';
import { useThemeColor } from '../Themed';

const cardImage = require('../../assets/images/membership_card.png');

type MembershipCardProps = ViewProps & {
    plan: Plan;
    profile?: boolean;
};

const MembershipCard = (props: MembershipCardProps): JSX.Element => {
    const { userProfile } = useAuth();
    const { plan, profile = false, style, ...rest } = props;

    const light = useThemeColor('light');

    let discountedPrice = parseFloat(plan.price);
    if (userProfile?.promoCode) {
        discountedPrice = discountedPrice - userProfile.promoCode.discountedCents / 100;
    }

    return (
        <>
            <View testID="membership-card" style={[styles.container, style]} {...rest}>
                {!profile && (
                    <>
                        <Image source={cardImage} resizeMode="contain" style={styles.card} />
                        <Label uppercase colorScheme="light">
                            Select features available (in beta)
                        </Label>
                        <Heading3 style={{ marginTop: 24 }}>
                            <Text style={{ fontSize: 18 }}>Premier</Text>{' '}
                            <Text
                                style={{
                                    color: light
                                }}
                            >
                                |
                            </Text>{' '}
                            {userProfile?.promoCode && (
                                <>
                                    <Text
                                        style={{
                                            textDecorationLine: 'line-through',
                                            textDecorationStyle: 'solid',
                                            fontSize: 18,
                                            color: light
                                        }}
                                    >
                                        {parseFloat(plan.price).toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD'
                                        })}
                                    </Text>{' '}
                                    {discountedPrice.toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD'
                                    })}
                                    *
                                </>
                            )}
                            {!userProfile?.promoCode && (
                                <>
                                    {parseFloat(plan.price).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD'
                                    })}
                                </>
                            )}
                        </Heading3>
                        <Label uppercase colorScheme="light">
                            Annual Member Fee
                        </Label>
                        {userProfile?.promoCode && (
                            <Label uppercase colorScheme="light">
                                Promo code applied*
                            </Label>
                        )}
                        <View style={styles.lineDivider} />
                    </>
                )}
                <LatRow>
                    <LatCol>
                        <Heading3>{plan.membership_bonus_points.toLocaleString()}</Heading3>
                        <Label colorScheme="light">
                            POINTS TOWARDS YOUR EQUITY PARTICIPATION ANNUALLY
                        </Label>
                    </LatCol>
                    <LatCol>
                        <Heading3>{`${(
                            plan.local_donation_percent * 100
                        ).toLocaleString()}%`}</Heading3>
                        <Label colorScheme="light">
                            OF UP TO $1,000 IN &apos;LOCAL&apos; PURCHASES DONATED ANNUALLY
                        </Label>
                    </LatCol>
                </LatRow>
                <LatRow style={{ marginTop: 16 }}>
                    <LatCol>
                        <Heading3>{plan.meals_donated.toLocaleString()}</Heading3>
                        <Label colorScheme="light">MEALS DONATED ANNUALLY IN THE COMMUNITY</Label>
                    </LatCol>
                    <LatCol>
                        <Heading3>{`${(
                            plan.high_yield_target_apy * 100
                        ).toLocaleString()}%`}</Heading3>
                        <Label colorScheme="light">APY ON HIGH YIELD ACCOUNT (COMING SOON)</Label>
                    </LatCol>
                </LatRow>
                <LatRow style={{ marginTop: 16 }}>
                    <LatCol>
                        <Heading3>{`${(
                            plan.lbn_cashback_percent * 100
                        ).toLocaleString()}%`}</Heading3>
                        <Label colorScheme="light">
                            ‍CASH BACK ON PURCHASES AT MERCHANTS IN THE LATITUDE BUSINESS NETWORK
                        </Label>
                    </LatCol>
                    <LatCol>
                        <Heading3>{`${(
                            plan.lbn_donation_percent * 100
                        ).toLocaleString()}%`}</Heading3>
                        <Label colorScheme="light">
                            ‍OF LATITUDE BUSINESS NETWORK PURCHASES DONATED TO A LATITUDE NON-PROFIT
                            PARTNER
                        </Label>
                    </LatCol>
                </LatRow>
                <LatRow style={{ marginTop: 16 }}>
                    <LatCol>
                        <Body colorScheme="light">Also includes:</Body>
                    </LatCol>
                </LatRow>
                <LatRow style={{ justifyContent: 'flex-start', width: '100%' }}>
                    <LatCol style={{ flex: 0 }}>
                        <Body style={{ lineHeight: 15 }}>•</Body>
                    </LatCol>
                    <LatCol style={{ paddingHorizontal: 0 }}>
                        <Label colorScheme="light">
                            <Label colorScheme="regular" fontWeight="bold">
                                3 Direct Referral Invites Annually
                            </Label>{' '}
                            (take part in building your banking patform and community)
                        </Label>
                    </LatCol>
                </LatRow>
                <LatRow style={{ justifyContent: 'flex-start', width: '100%' }}>
                    <LatCol style={{ flex: 0 }}>
                        <Body style={{ lineHeight: 15 }}>•</Body>
                    </LatCol>
                    <LatCol style={{ paddingHorizontal: 0 }}>
                        <Label colorScheme="light">
                            <Label colorScheme="regular" fontWeight="bold">
                                30k+ Fee-Free ATMs
                            </Label>{' '}
                            available via the Moneypass network
                        </Label>
                    </LatCol>
                </LatRow>
                <LatRow style={{ justifyContent: 'flex-start', width: '100%' }}>
                    <LatCol style={{ flex: 0 }}>
                        <Body style={{ lineHeight: 15 }}>•</Body>
                    </LatCol>
                    <LatCol style={{ paddingHorizontal: 0 }}>
                        <Label fontWeight="bold" colorScheme="light">
                            <Label colorScheme="regular" fontWeight="bold">
                                Premium Ticket Access
                            </Label>{' '}
                            to premier social, cultural and sporting events around the globe (COMING
                            SOON)
                        </Label>
                    </LatCol>
                </LatRow>
                <LatRow style={{ justifyContent: 'flex-start', width: '100%' }}>
                    <LatCol style={{ flex: 0 }}>
                        <Body style={{ lineHeight: 15 }}>•</Body>
                    </LatCol>
                    <LatCol style={{ paddingHorizontal: 0 }}>
                        <Label fontWeight="bold" colorScheme="light">
                            <Label colorScheme="regular" fontWeight="bold">
                                Curated Travel Experiences
                            </Label>{' '}
                            with personalized itineraries and bookings (COMING SOON)
                        </Label>
                    </LatCol>
                </LatRow>
                <LatRow style={{ justifyContent: 'flex-start', width: '100%' }}>
                    <LatCol style={{ flex: 0 }}>
                        <Body style={{ lineHeight: 15 }}>•</Body>
                    </LatCol>
                    <LatCol style={{ paddingHorizontal: 0 }}>
                        <Label colorScheme="regular" fontWeight="bold">
                            Tungsten (Metal) Black Debit Card
                        </Label>
                    </LatCol>
                </LatRow>

                <Label style={{ marginTop: 8 }}>
                    For additional details on this and other plans please visit{' '}
                    <TextLink
                        style={{ fontSize: 12 }}
                        onPress={async () => {
                            await WebBrowser.openBrowserAsync(
                                'https://www.getlatitude.com/membership'
                            );
                        }}
                    >
                        https://www.getlatitude.com/membership
                    </TextLink>
                </Label>
            </View>
            {!profile && (
                <Label>
                    {`
The perks and benefits members have access to are dependent upon their membership tier (see membership plans). Items marked coming soon (if applicable) are not yet available and will not be offered as part of your plan.
  ‍
Latitude is a financial technology company, not a bank. Banking services provided by Lewis and Clark Bank, Member FDIC. The Latitude Debit Card is issued by Lewis and Clark Bank pursuant to a license from Mastercard International Incorporated, and may be used everywhere Mastercard debit cards are accepted.
  ‍
© 2022 Digital Financial Corporation. All rights reserved.
  
* You generally have the option of enrolling: (i) as a Preferred Member; or (ii) for an annual membership fee (charged based on your account opening date) as either a Premier Member or Carte Blanche Member (the “Member Fee”). As a Preferred member, you are required to pay an annual account fee (charged based on your account opening date) of $195 (or $16.25 per month) related to your bank account made available by Lewis & Clark Bank, Member FDIC (the “Account Fee”). Under this circumstance, the Account Fee shall be waived for Premier Members and Carte Blanche Members.
  
** As you will only have the option of enrolling as a Premier Member during beta, the Premier Member Fee of $395 per year will be waived, and you will only be charged the annual account fee of $195 (or $16.25 per month) related to your bank account made available by Lewis & Clark Bank, Member FDIC (the "Account Fee"). Once the Preferred membership is available, you will be charged the standard Premier Member Fee on your renewal date (if applicable) and the Account Fee will be waived thereafter.
  
1. Based on your membership plan, you may earn, pursuant to the Points Reward Agreement, 2,500, 5,000 or 12,500 points towards your Equity Participation Plan when you sign up for or renew your membership.
2. Subject to the Donation Requirements within the Latitude Donation Plan, we may donate a percentage of your spend on your Latitude Debit Card to one of our local non-profit partners (the "Non-Profit Partners"). When you use your Latitude Debit Card at a Local Business, as defined within the Latitude Donation Plan, we may donate annually, pursuant to the terms of the Latitude Donation Plan, 2.5%, 5.0% or 7.5% of such spend (up to $1,000 in purchases) to Latitude's Non-Profit Partner of your choice. Once the $1,000 purchase limit is met, we will donate 0.15% of all Local Business purchases thereafter.
3. Subject to the Meal Donation Requirements within the Latitude Donation Plan, we will donate annually the equivalent cost of 5, 10 or 20 meals to a foodbank within your selected community as of the donation date. The foodbank that may be donated to is stated within our mobile app, and the foodbank will administer the meal distribution in its sole discretion.
4. As part of our offering, we intend to provide a High Yield Market Account (the "HY Account"). Based on your membership plan, we intend to offer a target annual percentage yield of either 1.5%, 2.5% or 3.5% (the "Interest Rates"). As the HY Account is not yet available, the Interest Rates (and other items) may change.
5. Based on your membership plan, you may receive, subject to the terms of the Cash Back Agreement, 5.0%, 10.0% or 12.5% cash back on purchases with your Latitude Debit Card at businesses part of the Latitude Business Network.
6. When you use your Latitude Debit Card at a business part of the Latitude Business Network (the "LBN"), we may donate annually, pursuant to the terms of the Latitude Donation Plan, 2.5% of such spend to the Non-Profit Partner of your choice.
`}
                </Label>
            )}
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.light.background,
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 16,
        maxWidth: Platform.OS === 'web' ? '500px' : undefined
    },
    card: {
        width: 230,
        height: 120
    },
    sectionSpacing: {
        marginBottom: 30
    },
    lineDivider: {
        marginVertical: 8,
        height: 1,
        width: '100%',
        backgroundColor: Colors.light.borders
    }
});

export default MembershipCard;
