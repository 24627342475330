import api from '../utils/api';

export const getCharities = async (params?: Record<string, any>): Promise<any> => {
    return await api
        .get('/charity/', {
            params
        })
        .then((response) => response.data);
};

export const updateUserCharity = async (charityId: number): Promise<any> => {
    return await api.put(`/charity/${charityId}/userCharity`).then((response) => response.data);
};

export default {
    getCharities,
    updateUserCharity
};
