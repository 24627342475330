import LatModal from '@latitude/components/LatModal';
import { LatRow } from '@latitude/components/LatRow';
import config from '@latitude/config/config';
import useStoredState from '@latitude/hooks/useStoredState';
import { Auth, AuthError } from '@latitude/models/Auth0';
import InviteService from '@latitude/services/InviteService';
import { FaceId, TouchId } from '@latitude/svg';
import client, { isAuthError } from '@latitude/utils/api';
import Storage from '@latitude/utils/storage';
import axios, { AxiosError } from 'axios';
import * as LocalAuthentication from 'expo-local-authentication';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppState, AppStateStatus } from 'react-native';
import StorageKeys from '../constants/SecureStoreKeys';
import { LatitudeUser } from '../models';
import { mapToLatitudeUser } from '../models/LatitudeUser';
import * as AuthService from '../services/AuthService';
import * as CustomerService from '../services/CustomerService';

// Biometric data is encrypted and stored for login
export type BiometricAuthData = {
    emailAddress: string;
    password: string;
};

export type GetOobCodeResponseData = {
    oobCode: string;
    phoneNumber: string;
};

export type ValidateCodeResponse = {
    valid: boolean;
    codeType: 'promoCode' | 'inviteCode';
};

export type AuthContextProps = {
    isAuthenticated: boolean;
    accessToken?: string | null;
    userProfile?: LatitudeUser;
    rememberedEmail?: string | null;
    password?: string;
    supportsFacialRecognition?: boolean;
    supportsFingerprint?: boolean;
    hasBiometricsEnrolled?: boolean;
    biometricLoginEnabled?: boolean;
    enableBiometricAfterLogin?: boolean;
    deleteRememberedEmail: () => Promise<void>;
    login: (email: string, password: string, rememberEmail?: boolean) => Promise<void>;
    signup: (
        email: string,
        password: string,
        inviteCode?: string,
        promoCode?: string
    ) => Promise<void>;
    logout: () => void;
    refreshUserProfile: () => Promise<any>;
    completeLoginMfa: (codeNumber: string, rememberEmail: boolean) => Promise<any>;
    completeOnboardingMfa: (codeNumber: string) => Promise<any>;
    getOobCode: (
        token?: string,
        mode?: 'challenge' | 'associate',
        phoneNumber?: string
    ) => Promise<GetOobCodeResponseData>;
    promptEnableBiometricLogin: () => Promise<boolean>;
    promptLoginUsingBiometrics: () => Promise<void>;
    checkForBiometrics: () => Promise<void>;
    setEnableBiometricAfterLogin: (value: boolean) => Promise<void>;
    disableBiometricLogin: () => Promise<void>;
    validateInviteCode: (code: string) => Promise<ValidateCodeResponse>;
    isLoading: boolean;
};

export const AuthContext = React.createContext<AuthContextProps>({
    isAuthenticated: false,
    isOnboarding: false,
    accessToken: null,
    userProfile: {},
    rememberedEmail: undefined,
    password: undefined,
    supportsFacialRecognition: undefined,
    supportsFingerprint: undefined,
    hasBiometricsEnrolled: undefined,
    biometricLoginEnabled: undefined,
    enableBiometricAfterLogin: undefined,
    deleteRememberedEmail: () => Promise.resolve(),
    login: () => Promise.resolve(),
    signup: () => Promise.resolve(),
    completeLoginMfa: () => Promise.resolve(),
    completeOnboardingMfa: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    refreshUserProfile: () => Promise.resolve(),
    getOobCode: () => Promise.resolve(),
    promptEnableBiometricLogin: () => Promise.resolve(false),
    promptLoginUsingBiometrics: () => Promise.resolve(),
    checkForBiometrics: () => Promise.resolve(),
    setEnableBiometricAfterLogin: () => Promise.resolve(),
    disableBiometricLogin: () => Promise.resolve(),
    validateInviteCode: () => Promise.resolve(),
    isLoading: true
});

export interface AuthProviderProps {
    children?: JSX.Element;
}

// Note this account also has mfa disabled in Auth0
const DEMO_MODE_ACCOUNT = 'jonathan+apple@getlatitude.com';

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
    const [auth, setAuth] = useState<Auth | undefined>();
    const [rememberedEmail, setRememberEmail] = useStoredState<string | undefined>(
        StorageKeys.REMEMBERED_EMAIL
    );
    const [userProfile, setUserProfile] = useState<LatitudeUser | undefined>();
    const [oobCode, setOobCode] = useState('');
    const [mfaToken, setMfaToken] = useState('');
    // Password is stored and used for MFA verification and for faceid
    const [password, setPassword] = useStoredState<string>(StorageKeys.PASSWORD);
    const [supportsFacialRecognition, setSupportsFacialRecognition] = useState(false);
    const [supportsFingerprint, setSupportsFingerprint] = useState(false);
    const [biometricData, setBiometricData] = useState<BiometricAuthData>();
    const [hasBiometricsEnrolled, setHasBiometricsEnrolled] = useState(false);
    const [enableBiometricAfterLogin, setEnableBiometricAfterLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [enableBiometricLoginModalVisible, setEnableBiometricLoginModalVisible] = useState(false);
    const [biometricSuccessModalVisible, setBiometricSuccessModalVisible] = useState(false);
    const appState = useRef(AppState.currentState);
    const [appStateVisible, setAppStateVisible] = useState(appState.current);

    useEffect(() => {
        checkForBiometrics();
        buildInterceptors();
        loadBiometricData();
    }, []);

    useEffect(() => {
        if (auth?.access_token) {
            loadCurrentUser();
        } else {
            setUserProfile(undefined);
        }
    }, [auth]);

    useEffect(() => {
        AppState.addEventListener('change', _handleAppStateChange);

        return () => {
            AppState.removeEventListener('change', _handleAppStateChange);
        };
    }, []);

    const _handleAppStateChange = async (nextAppState: AppStateStatus) => {
        if (appState.current.match(/inactive|background/) && nextAppState === 'active' && auth) {
            await refreshUserProfile();
        }

        appState.current = nextAppState;
        setAppStateVisible(appState.current);
    };

    const getStoredSession = async (): Promise<Auth | undefined> => {
        const authDataString = await Storage.getItem(StorageKeys.SESSION_TOKEN);
        if (authDataString) {
            return JSON.parse(authDataString) as Auth;
        }
    };

    const setStoredSession = async (auth: Auth): Promise<void> => {
        await Storage.setItem(StorageKeys.SESSION_TOKEN, JSON.stringify(auth));
    };

    const loadBiometricData = async () => {
        const biometricAuthDataJsonString = await Storage.getItem(StorageKeys.BIOMETRIC_AUTH_DATA);
        const biometricAuthData = JSON.parse(biometricAuthDataJsonString) as BiometricAuthData;
        setBiometricData(biometricAuthData);
        const storedAuth = await getStoredSession();
        if (storedAuth) {
            setAuth(storedAuth);
        } else {
            setIsLoading(false);
        }
    };

    const loadCurrentUser = async (): Promise<void> => {
        try {
            setIsLoading(true);
            await refreshUserProfile();
        } catch (err) {
            await logout();
        } finally {
            setIsLoading(false);
        }
    };

    const deleteRememberedEmail = async (): Promise<void> => {
        await Storage.removeItem(StorageKeys.REMEMBERED_EMAIL);
    };

    const login = async (
        email: string,
        password: string,
        rememberEmail?: boolean
    ): Promise<void> => {
        if (rememberEmail) {
            await setRememberEmail(email);
        } else {
            await setRememberEmail(undefined);
        }

        // Force apple demo account to demo mode
        // Demo mode just points to integration
        if (email === DEMO_MODE_ACCOUNT) {
            await Storage.setItem(StorageKeys.DEMO_MODE_ENABLED, 'true');
        } else {
            await Storage.removeItem(StorageKeys.DEMO_MODE_ENABLED);
        }

        const data = await AuthService.getAuthToken(
            email,
            password,
            rememberEmail ?? false,
            'default',
            'offline_access'
        );

        if (data && data.access_token) {
            await setStoredSession(data);
            setAuth(data);
            // Store password for faceid or mfa
            setPassword(password);
            await refreshUserProfile();
            setEnableBiometricLoginModalVisible(enableBiometricAfterLogin);
        }
    };

    const signup = async (
        email: string,
        password: string,
        inviteCode?: string,
        promoCode?: string
    ): Promise<void> => {
        const { data } = await CustomerService.createUser(email, password, inviteCode, promoCode);

        if (data && data.access_token) {
            await setStoredSession(data);
            setAuth(data);
            setPassword(password);
        }
    };

    const validateInviteCode = async (code: string): Promise<ValidateCodeResponse> => {
        const response = await InviteService.validateInviteCode(code);

        return response;
    };

    const getOobCode = async (
        newMfaToken?: string,
        mode: 'challenge' | 'associate' = 'challenge',
        phoneNumber?: string
    ): Promise<GetOobCodeResponseData> => {
        const tokenToUse = newMfaToken ?? mfaToken;
        const response = await CustomerService.getOobCode(tokenToUse, mode, phoneNumber);
        const oobCode = response.data.oobCode;
        const phone = phoneNumber ?? response.data.phoneNumber;
        setMfaToken(tokenToUse);
        setOobCode(oobCode);
        return {
            oobCode,
            phoneNumber: phone
        };
    };

    const logout = async (): Promise<void> => {
        setIsLoading(false);
        await Storage.removeItem(StorageKeys.SESSION_TOKEN);
        setAuth(undefined);
    };

    const completeLoginMfa = async (codeNumber: string, rememberEmail: boolean): Promise<any> => {
        const response = await CustomerService.completeMfa(
            codeNumber,
            mfaToken,
            oobCode,
            rememberEmail
        );

        if (!response.success) {
            return response;
        }

        if (response.data.access_token) {
            setAuth(response.data);
            await setStoredSession(response.data);
            if (rememberEmail) {
                await setRememberEmail(rememberedEmail);
            } else {
                await deleteRememberedEmail();
            }

            await refreshUserProfile();
        }
        return response;
    };

    const completeOnboardingMfa = async (codeNumber: string): Promise<any> => {
        const response = await CustomerService.completeMfa(
            codeNumber,
            mfaToken,
            oobCode,
            !!rememberedEmail
        );
        return response;
    };

    const refreshUserProfile = async (): Promise<void> => {
        try {
            const data = await CustomerService.getCustomer();
            const userProfile = mapToLatitudeUser(data.data);
            // console.log({ userProfile });
            if (data && data.success) {
                setUserProfile(userProfile);
            } else {
                await logout();
            }
        } catch (err) {
            await logout();
            console.error(err);
        }
    };

    const checkForBiometrics = async (): Promise<void> => {
        const hasHardware = await LocalAuthentication.hasHardwareAsync();

        if (hasHardware) {
            const supportedTypes = await LocalAuthentication.supportedAuthenticationTypesAsync();
            setSupportsFacialRecognition(
                supportedTypes.includes(LocalAuthentication.AuthenticationType.FACIAL_RECOGNITION)
            );
            setSupportsFingerprint(
                supportedTypes.includes(LocalAuthentication.AuthenticationType.FINGERPRINT)
            );

            if (supportedTypes.length > 0) {
                const isEnrolled = await LocalAuthentication.isEnrolledAsync();

                setHasBiometricsEnrolled(isEnrolled);

                // Clear previous biometric auth data if fingerprints or face ID are not registered or enabled on the device
                if (!isEnrolled) {
                    await disableBiometricLogin();
                }
            }
        } else {
            setHasBiometricsEnrolled(false);
        }
    };

    const authenticateUsingHardware = async (
        options?: LocalAuthentication.LocalAuthenticationOptions
    ): Promise<boolean> => {
        const result = await LocalAuthentication.authenticateAsync(options);

        return result.success;
    };

    const promptEnableBiometricLogin = async (): Promise<boolean> => {
        const hardwareAuthSuccess = await authenticateUsingHardware();

        if (hardwareAuthSuccess && userProfile) {
            const data = {
                emailAddress: userProfile.email,
                password
            } as BiometricAuthData;
            await Storage.setItem(StorageKeys.BIOMETRIC_AUTH_DATA, JSON.stringify(data));
            setBiometricData(data);
            setEnableBiometricAfterLogin(true);
        }

        setEnableBiometricAfterLogin(false);

        return hardwareAuthSuccess;
    };

    const promptLoginUsingBiometrics = async (): Promise<void> => {
        const hardwareAuthSuccess = await authenticateUsingHardware();

        if (hardwareAuthSuccess) {
            const biometricAuthDataJsonString = await Storage.getItem(
                StorageKeys.BIOMETRIC_AUTH_DATA
            );
            const biometricAuthData = JSON.parse(biometricAuthDataJsonString) as BiometricAuthData;

            if (biometricAuthData) {
                try {
                    await login(biometricAuthData.emailAddress, biometricAuthData.password, true);
                } catch (err) {
                    console.error(err.response.data);
                    if (err.response.status === 403) {
                        // If face id fails delete biometric data
                        await disableBiometricLogin();
                    }
                }
            }
        }
    };

    const onEnableBiometricLoginPress = async (): Promise<void> => {
        setEnableBiometricLoginModalVisible(false);
        const success = await promptEnableBiometricLogin();
        if (success) {
            setBiometricSuccessModalVisible(true);
        }
    };

    const onCancelEnableBiometricLogin = (): void => {
        setEnableBiometricLoginModalVisible(false);
    };

    const onCloseBiometricSuccessModal = (): void => {
        setBiometricSuccessModalVisible(false);
    };

    const disableBiometricLogin = async () => {
        await Storage.removeItem(StorageKeys.BIOMETRIC_AUTH_DATA);
        setBiometricData(undefined);
    };

    // Should be run once from effect
    const buildInterceptors = () => {
        // Add Bearer token to requests when access token exists
        client.interceptors.request.use(
            async (request) => {
                // console.log({ url: request.url });
                // Check demo mode
                const demoMode = (await Storage.getItem(StorageKeys.DEMO_MODE_ENABLED)) === 'true';
                // If demo mode is enabled change the baseUrl
                if (demoMode) {
                    request.baseURL = config.demo.api.baseUrl;
                } else {
                    request.baseURL = config.api.baseUrl;
                }

                // If this is a non auth route attach Bearer token
                if (request.headers) {
                    const session = await getStoredSession();

                    if (session?.access_token) {
                        request.headers['Authorization'] = `Bearer ${session.access_token}`;
                    }
                }
                return request;
            },
            (error) => {
                Promise.reject(error);
            }
        );

        //Add a response interceptor, try to refresh token and try again on 401s
        client.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                const originalRequest = error.config;

                if (error.response && error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    const session = await getStoredSession();
                    if (session?.refresh_token) {
                        const authData = await refresh(session.refresh_token);
                        if (!isAuthError(authData)) {
                            originalRequest.headers[
                                'Authorization'
                            ] = `Bearer ${authData.access_token}`;
                            return axios(originalRequest);
                        } else {
                            await logout();
                        }
                    } else {
                        await logout();
                    }
                }
                return Promise.reject(error);
            }
        );
    };

    const refresh = async (refreshToken: string): Promise<Auth | AuthError> => {
        try {
            const data = await AuthService.refreshAuthToken(refreshToken);
            await setStoredSession(data);
            setAuth(data);
            return data;
        } catch (error: unknown | AxiosError) {
            if (axios.isAxiosError(error) && error) {
                if (error.response?.data?.error) {
                    return error.response?.data as AuthError;
                }
            }
            throw error;
        }
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated: !!userProfile?.status,
                accessToken: auth?.access_token,
                userProfile,
                rememberedEmail,
                password,
                supportsFacialRecognition,
                supportsFingerprint,
                hasBiometricsEnrolled,
                biometricLoginEnabled: !!biometricData?.emailAddress,
                isLoading,
                deleteRememberedEmail: async () => {
                    setRememberEmail(undefined);
                },
                login,
                signup,
                completeLoginMfa,
                completeOnboardingMfa,
                logout,
                refreshUserProfile,
                getOobCode,
                promptEnableBiometricLogin,
                promptLoginUsingBiometrics,
                setEnableBiometricAfterLogin: async (value: boolean) => {
                    setEnableBiometricAfterLogin(value);
                },
                validateInviteCode,
                checkForBiometrics,
                disableBiometricLogin
            }}
        >
            {children}
            <LatModal
                visible={biometricSuccessModalVisible}
                title={`${supportsFacialRecognition ? 'Face ID' : 'Touch ID'} Enabled`}
                description={`${
                    supportsFacialRecognition ? 'Face ID' : 'Touch ID'
                } has been enabled you can update this anytime in ‘Settings’ under your Profile. `}
                onClose={onCloseBiometricSuccessModal}
                buttons={[
                    {
                        type: 'Secondary',
                        text: 'Close',
                        onPress: onCloseBiometricSuccessModal
                    }
                ]}
            />
            <LatModal
                visible={enableBiometricLoginModalVisible}
                title={`Enable ${supportsFacialRecognition ? 'Face Id' : 'Touch Id'}?`}
                description={`Enabling ${
                    supportsFacialRecognition ? 'face id' : 'touch id'
                } will keep your account secure and allow for easy login. You can update this later in your ‘settings’.`}
                contentBefore={
                    <LatRow justifyContent="center">
                        {supportsFacialRecognition ? (
                            <FaceId height={64} width={64} style={{ marginBottom: 24 }} />
                        ) : (
                            <TouchId height={64} width={64} style={{ marginBottom: 24 }} />
                        )}
                    </LatRow>
                }
                onClose={onCancelEnableBiometricLogin}
                tapOutsideToDismiss={false}
                buttons={[
                    {
                        type: 'Primary',
                        text: `Enable ${supportsFacialRecognition ? 'Face ID' : 'Touch ID'}`,
                        onPress: onEnableBiometricLoginPress
                    },
                    {
                        type: 'TextLink',
                        text: 'No, maybe later',
                        onPress: onCancelEnableBiometricLogin
                    }
                ]}
            />
        </AuthContext.Provider>
    );
};

export const AuthConsumer = AuthContext.Consumer;

export const useAuth = (): AuthContextProps => useContext(AuthContext);
