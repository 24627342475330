import api from '../utils/api';

export const getComplianceDocuments = async (): Promise<any> => {
    return await api.get('/compliance/documents').then((response) => response.data);
};

export const acknowledgeComplianceDocument = async (
    workflowUid: string,
    documentUid: string,
    userName?: string
): Promise<any> => {
    return await api
        .put('/compliance/agree', {
            workflowUid,
            documentUid,
            userName
        })
        .then((response) => response.data);
};

export default {
    getComplianceDocuments,
    acknowledgeComplianceDocument
};
