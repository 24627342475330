const light = {
    dark: '#242225',
    background: '#2d2a2e',
    darkBackground: '#1d1b1e',
    borders: '#363636',
    light: '#9c9c9c',
    regular: '#d3d3d3',
    primary: '#006760',
    primaryLight: '#009e93',
    primaryContrast: '#004d47',
    secondary: '#b38b7d',
    bronze: '#806359',
    error: '#e67373',
    dustyGray: '#979797',
    progressBarBg: '#d8d8d8',
    mustard: '#e6bf73'
};

export default {
    light,
    dark: light
};
