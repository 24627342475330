import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12" width={18} height={12} {...props}>
        <Path
            d="M2.636.879a1 1 0 0 1 1.414 0L9 5.829l4.95-4.95a1 1 0 0 1 1.414 0l1.414 1.414a1 1 0 0 1 0 1.414l-7.07 7.071a1 1 0 0 1-1.415 0l-7.071-7.07a1 1 0 0 1 0-1.415L2.636.879z"
            fill="#B38B7D"
            fillRule="evenodd"
        />
    </Svg>
);

export default SvgComponent;
