import { useAuth } from '@latitude/contexts/Auth';
import * as SecureStore from 'expo-secure-store';
import React, { useEffect, useState } from 'react';
import { ImageBackground, StyleSheet, View } from 'react-native';
import SecureStoreKeys from '../constants/SecureStoreKeys';
import { marketHeroImages } from '../models';
import { Body, Quote } from './StyledText';

export const HERO_HEIGHT = 331;

export type HeroProps = View['props'] & {
    name?: string;
    marketCode?: string;
};

const evalGreeting = (): string => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
        return 'Good Morning';
    } else if (currentHour < 18) {
        return 'Good Afternoon';
    } else {
        return 'Good Evening';
    }
};

export const Hero = (props: HeroProps): JSX.Element => {
    const { style, name, marketCode, ...otherProps } = props;

    const heroImages = marketHeroImages[marketCode] ?? marketHeroImages.la;

    const { userProfile } = useAuth();

    const [greeting, setGreeting] = useState<string>(evalGreeting());
    const [heroImageIndex, setHeroImageIndex] = useState<number>(0);

    const defaultStyles = StyleSheet.create({
        bg: {
            height: HERO_HEIGHT,
            paddingHorizontal: 16,
            paddingTop: 146,
            display: 'flex',
            justifyContent: 'space-between'
        },
        quote: {
            maxWidth: 245
        }
    });

    useEffect(() => {
        SecureStore.getItemAsync(SecureStoreKeys.HERO_BACKGROUND_INDEX).then((indexString) => {
            setHeroImageIndex(parseInt(indexString ?? '0'));
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setGreeting(evalGreeting());
        }, 1000);
        return (): void => clearInterval(interval);
    }, []);

    return (
        <ImageBackground
            source={heroImages[heroImageIndex]}
            style={[defaultStyles.bg, style]}
            {...otherProps}
        >
            <Quote style={defaultStyles.quote}>{`${greeting}\n${name ?? ''}`}</Quote>
            <Body style={{ marginBottom: 12 }}>{`${userProfile?.market?.name}\nMember since ‘${
                (userProfile?.createdAt?.getFullYear() || 2021) - 2000
            }`}</Body>
        </ImageBackground>
    );
};
