import * as React from 'react';
import { StyleSheet, View } from 'react-native';

export type LatRowProps = View['props'] & {
    justifyContent?:
        | 'center'
        | 'flex-end'
        | 'flex-start'
        | 'space-between'
        | 'space-around'
        | 'space-evenly';
    alignItems?: 'center' | 'flex-end' | 'flex-start';
    viewRef?: React.LegacyRef<View>;
};

const defaultStyles = StyleSheet.create({
    latRow: {
        marginHorizontal: -8,
        marginVertical: 4,
        flexDirection: 'row'
    }
});

export const LatRow = (props: LatRowProps): JSX.Element => {
    const { style, alignItems, justifyContent, ...rest } = props;

    return (
        <View
            ref={props.viewRef}
            style={[
                defaultStyles.latRow,
                justifyContent && { justifyContent },
                alignItems && { alignItems },
                style
            ]}
            {...rest}
        />
    );
};
