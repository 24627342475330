import { ALL_POINT_TYPES, MarketTotal, PointBreakdown } from '@latitude/models';
import { CharityTotal } from '@latitude/models/Charity';
import PointService from '@latitude/services/PointService';
import { InitSlice } from './slice';

export enum PointStatType {
    USER_TOTAL = 'userTotal',
    USER_TOTAL_MEALS = 'userTotalMeals',
    MARKET_TOTAL_MEALS = 'marketTotalMeals',
    POINT_BREAKDOWN = 'pointBreakdown',
    USER_REINVESTMENT = 'userReinvestment',
    MARKET_REINVESTMENT = 'marketReinvestment',
    AVERAGE_POINTS = 'averagePoints',
    TOP_POINTS = 'topPoints',
    USER_NON_LOCAL = 'userNonLocal',
    CHARITY_DONATIONS = 'charityDonations',
    DONATIONS_BY_CHARITY = 'donationsByCharity',
    TOTAL_MEALS = 'totalMeals'
}

export interface PointsState {
    isLoading: boolean;
    userTotal: number;
    userTotalMeals: number;
    pointBreakdown: Array<PointBreakdown>;
    userTotalReinvestment: number;
    userNonLocal: number;
    marketTotalMeals: Array<MarketTotal>;
    marketTotalReinvestment: Array<MarketTotal>;
    topPoints: number;
    averagePoints: number;
    charityDonations: number;
    totalMeals: number;
    donationsByCharity: Array<CharityTotal>;
    refetchPointStats: (include: Array<PointStatType>) => Promise<any>;
    reset: () => void;
}

const initialState = {
    isLoading: false,
    userTotal: 0,
    userTotalMeals: 0,
    pointBreakdown: [],
    userTotalReinvestment: 0,
    userNonLocal: 0,
    marketTotalMeals: [],
    marketTotalReinvestment: [],
    topPoints: 0,
    averagePoints: 0,
    charityDonations: 0,
    totalMeals: 0,
    donationsByCharity: []
};

export const initPointsSlice: InitSlice<PointsState> = (set, get) => ({
    ...initialState,
    reset: () => {
        set(initialState);
    },
    refetchPointStats: async (include: Array<PointStatType>) => {
        try {
            set({ isLoading: true });
            const pointsResponse = await PointService.getPointStats(include);
            if (pointsResponse && pointsResponse.success) {
                if (pointsResponse.data.userTotal) {
                    set({ userTotal: parseFloat(pointsResponse.data.userTotal) });
                }
                if (pointsResponse.data.userTotalMeals) {
                    set({ userTotalMeals: parseFloat(pointsResponse.data.userTotalMeals) });
                }
                if (pointsResponse.data.pointBreakdown) {
                    const polyFilledPoints = ALL_POINT_TYPES.map((p) => {
                        const existingPoint = pointsResponse.data.pointBreakdown.find(
                            (b: PointBreakdown) => b.point_type === p
                        ) ?? { point_type: p, total: '0' };
                        return existingPoint as PointBreakdown;
                    });
                    set({ pointBreakdown: polyFilledPoints });
                }
                if (pointsResponse.data.marketTotalMeals) {
                    set({ marketTotalMeals: pointsResponse.data.marketTotalMeals });
                }
                if (pointsResponse.data.userReinvestment) {
                    set({ userTotalReinvestment: pointsResponse.data.userReinvestment });
                }
                if (pointsResponse.data.marketReinvestment) {
                    set({ marketTotalReinvestment: pointsResponse.data.marketReinvestment });
                }
                if (pointsResponse.data.topPoints) {
                    set({ topPoints: pointsResponse.data.topPoints });
                }
                if (pointsResponse.data.averagePoints) {
                    set({ averagePoints: pointsResponse.data.averagePoints });
                }
                if (pointsResponse.data.userNonLocal) {
                    set({ userNonLocal: pointsResponse.data.userNonLocal });
                }
                if (pointsResponse.data.charityDonations) {
                    set({ charityDonations: pointsResponse.data.charityDonations });
                }
                if (pointsResponse.data.donationsByCharity) {
                    set({ donationsByCharity: pointsResponse.data.donationsByCharity });
                }
                if (pointsResponse.data.totalMeals) {
                    set({ totalMeals: pointsResponse.data.totalMeals });
                }
            }
        } finally {
            set({ isLoading: false });
        }
    }
});
