import Constants from 'expo-constants';
import validator from 'validator';

type Auth0Config = {
    domain: string;
    clientId: string;
    audience: string;
    managementAudience: string;
};

export type Config = {
    phoneNumberPrefix: string;
    phoneNumberLocale: validator.MobilePhoneLocale;
    auth0: Auth0Config;
    pinCodeUrl: string;
    appScheme: string;
    isDevelopment: boolean;
    api: {
        baseUrl: string;
    };
    demo: {
        auth0: Auth0Config;
        api: {
            baseUrl: string;
        };
    };
    sentry: {
        dsn: string;
        environment: string;
        debug: boolean;
        enableInDevelopment: boolean;
    };
    segment: {
        ios: string;
        android: string;
        web: string;
    };
};

const expoExtras = Constants.manifest2?.extra?.expoClient?.extra ?? Constants.manifest?.extra;

export default {
    ...expoExtras,
    phoneNumberPrefix: '+1',
    phoneNumberLocale: 'en-US'
} as Config;
