import { Plan } from '@latitude/models/Subscription';
import PlanService from '@latitude/services/PlanService';
import { InitSlice } from './slice';

export interface SubscriptionState {
    isLoading: boolean;
    plans: Plan[];
    init: () => Promise<void>;
}

export const initSubscriptionSlice: InitSlice<SubscriptionState> = (set, get) => ({
    isLoading: false,
    plans: [],
    init: async () => {
        try {
            set({ isLoading: true });

            const planResponse = await PlanService.getPlans();

            if (planResponse.success) {
                const data = planResponse.data;
                set({ isLoading: false, plans: data.sort((a, b) => a.plan_id - b.plan_id) });
            }
        } finally {
            set({ isLoading: false });
        }
    }
});
