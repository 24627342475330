import Loader from '@latitude/components/Loader';
import React, { useContext, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useThemeColor } from '../components/Themed';

export type LoadingContextProps = {
    isLoading: boolean;
    setIsLoading: (arg0: boolean) => void;
};

export const LoadingContext = React.createContext<LoadingContextProps>({
    isLoading: true,
    setIsLoading: () => Promise.resolve()
});
export interface LoadingProviderProps {
    children?: JSX.Element;
}

export const LoadingProvider = ({ children }: LoadingProviderProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);

    const styles = StyleSheet.create({
        container: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: useThemeColor('dark'),
            opacity: 0.5,
            zIndex: 9999
        }
    });

    return (
        <LoadingContext.Provider
            value={{
                isLoading,
                setIsLoading
            }}
        >
            {children}
            {isLoading && (
                <View style={styles.container}>
                    <Loader size="medium" />
                </View>
            )}
        </LoadingContext.Provider>
    );
};

export const LoadingConsumer = LoadingContext.Consumer;

export const useLoading = (): LoadingContextProps => useContext(LoadingContext);
