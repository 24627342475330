import * as React from 'react';
import { Pressable, PressableProps, StyleProp, StyleSheet, TextStyle } from 'react-native';
import Colors from '../constants/Colors';
import { Text, TextProps, useThemeColor } from './Themed';

export type StyledTextProps = TextProps & {
    textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify';
    colorScheme?: 'regular' | 'light' | 'primaryLight' | 'dark' | 'error';
    color?: keyof typeof Colors.light & keyof typeof Colors.dark;
    uppercase?: boolean;
};

const StyledText = (
    defaultStyle: StyleProp<TextStyle>,
    defaultColor?: keyof typeof Colors.light & keyof typeof Colors.dark
): React.FC<StyledTextProps> => {
    const BasicText: React.FC<StyledTextProps> = ({
        style,
        colorScheme,
        color,
        textAlign,
        uppercase,
        ...props
    }: StyledTextProps) => {
        return (
            <Text
                style={[
                    defaultStyle,
                    { color: useThemeColor(colorScheme ?? 'regular') },
                    defaultColor && { color: useThemeColor(defaultColor) },
                    color && { color: useThemeColor(color) },
                    textAlign && { textAlign },
                    uppercase && { textTransform: 'uppercase' },
                    style
                ]}
                {...props}
            />
        );
    };

    return BasicText;
};

export const textStyles = StyleSheet.create({
    title: {
        fontSize: 48,
        fontFamily: 'ChronicleDisplay',
        lineHeight: 48
    },
    h1: {
        fontSize: 40,
        fontFamily: 'ChronicleDisplay-Bold',
        lineHeight: 48
    },
    h1Large: {
        fontSize: 56,
        fontFamily: 'ChronicleDisplay-Bold',
        lineHeight: 64
    },
    h2: {
        fontSize: 32,
        fontFamily: 'ChronicleDisplay-Bold',
        lineHeight: 40
    },
    h3: {
        fontSize: 24,
        fontFamily: 'ChronicleDisplay-Semibold',
        lineHeight: 32
    },
    h4: {
        fontSize: 18,
        fontFamily: 'ChronicleDisplay-Semibold',
        lineHeight: 24
    },
    quote: {
        fontSize: 24,
        fontFamily: 'ProximaNova-Regular',
        lineHeight: 32
    },
    body: {
        fontSize: 16,
        fontFamily: 'ProximaNova-Regular',
        lineHeight: 24
    },
    bodySemibold: {
        fontFamily: 'ProximaNova-Semibold'
    },
    bodyBold: {
        fontFamily: 'ProximaNova-Bold'
    },
    label: {
        fontFamily: 'ProximaNova-Regular',
        fontSize: 12,
        lineHeight: 16
    },
    labelSemibold: {
        fontFamily: 'ProximaNova-Semibold'
    },
    labelBold: {
        fontFamily: 'ProximaNova-Bold'
    },
    textLink: {
        fontFamily: 'ProximaNova-Bold',
        fontSize: 16,
        lineHeight: 24
    }
});

export const Title = StyledText(textStyles.title);
export const Heading1Large = StyledText(textStyles.h1Large);
export const Heading1 = StyledText(textStyles.h1);
export const Heading2 = StyledText(textStyles.h2);
export const Heading3 = StyledText(textStyles.h3);
export const Heading4 = StyledText(textStyles.h4);

export type BodyProps = StyledTextProps & {
    fontWeight?: 'regular' | 'semibold' | 'bold';
};

export const Body: React.FC<BodyProps> = ({ style, fontWeight, ...props }: BodyProps) => {
    const bodyStyles: StyleProp<TextStyle> = [textStyles.body];

    switch (fontWeight) {
        case 'semibold':
            bodyStyles.push(textStyles.bodySemibold);
            break;
        case 'bold':
            bodyStyles.push(textStyles.bodyBold);
            break;
    }

    return StyledText(bodyStyles)({ style, ...props });
};

export const Label: React.FC<BodyProps> = ({ style, fontWeight, ...props }: BodyProps) => {
    const labelStyles: StyleProp<TextStyle> = [textStyles.label];

    switch (fontWeight) {
        case 'semibold':
            labelStyles.push(textStyles.labelSemibold);
            break;
        case 'bold':
            labelStyles.push(textStyles.labelBold);
            break;
    }

    return StyledText(labelStyles)({ style, ...props });
};

export const TextLink = StyledText(textStyles.textLink, 'secondary');
export const Quote = StyledText(textStyles.quote);

interface TextLinkButtonProps extends PressableProps {
    color?: keyof typeof Colors.light;
    disabledColor?: keyof typeof Colors.light;
    textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify';
    textStyle?: TextStyle;
}

export const TextLinkButton = (props: TextLinkButtonProps): JSX.Element => {
    const { textStyle, textAlign, color, disabledColor, children, ...rest } = props;
    return (
        <Pressable {...rest}>
            {({ pressed }): JSX.Element => (
                <TextLink
                    color={props.disabled ? disabledColor ?? color : color}
                    textAlign={textAlign}
                    style={[{ textDecorationLine: pressed ? 'underline' : 'none' }, textStyle]}
                    numberOfLines={1}
                >
                    {children}
                </TextLink>
            )}
        </Pressable>
    );
};
