import * as Linking from 'expo-linking';

export default {
    prefixes: [Linking.makeUrl('/')],
    config: {
        screens: {
            Signup: 'signup',
            Login: 'login',
            RequestInvite: 'request',
            AccountSummary: 'account-summary',
            AccountDetails: 'account-details',
            TransactionDetails: 'transaction-details',
            MFACodeConfirmation: 'mfa-code-confirmation',
            TermsAndConditions: 'terms-and-conditions',
            UserInformation: 'user-information',
            PDFReader: 'pdf-reader',
            Fund: 'fund',
            HomeAddress: 'home-address',
            Market: 'Market',
            Subscription: 'subscription',
            DateOfBirth: 'date-of-birth',
            DateOfBirthUnder18: 'date-of-birth-under-18',
            SSN: 'ssn',
            ReviewPersonalInfo: 'review-personal-info',
            BankingDisclosures: 'banking-disclosures',
            ProcessingApplication: 'processing-application',
            OnboardingRejected: 'onboarding-rejected',
            ManualReview: 'manual-review',
            AccountOpened: 'account-opened',
            ManualFunding: 'manual-funding',
            OnboardingMoveMoney: 'onboarding-move-money',
            Transfers: 'transfers',
            AddExternalAccount: 'add-external-account',
            ExternalAccountDetails: 'external-account-details',
            MyProfileScreen: 'my-profile',
            Settings: 'settings',
            MembershipDetails: 'membership-details',
            SignedDisclosures: 'signed-disclosures',
            OnboardingTransferInitiated: 'onboarding-transfer-initiated',
            SignedDisclosuresPatriotAct: 'signed-disclosure-patriot-act',
            ForgotPassword: 'forgot-password',
            ForgotPasswordCheckMail: 'forgot-password-check-mail',
            ChangePassword: 'change-password',
            InvitePending: 'invite-pending',
            Membership: 'membership',
            NotFound: '*'
        }
    }
};
