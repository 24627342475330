import { SyntheticAccountCategory } from '../models';
import api from '../utils/api';

export const getAccounts = async (): Promise<any> => {
    return await api.get('/account/list').then((response) => response.data);
};

export const createAccount = async (
    name: string,
    accountNumber?: string,
    routingNumber?: string,
    category?: SyntheticAccountCategory
): Promise<any> => {
    return await api
        .post('/account/create', {
            name,
            category,
            accountNumber,
            routingNumber
        })
        .then((response) => response.data);
};

export const getNonlocalName = async (name: string): Promise<any> => {
    return await api
        .get('/blacklist/searchByName', {
            params: { name }
        })
        .then((response) => response.data);
};

export const getCashbackTotal = async (): Promise<any> => {
    return await api.get('/cashback/total').then((response) => response.data);
};

export default {
    getAccounts,
    createAccount,
    getNonlocalName,
    getCashbackTotal
};
