import api from '../utils/api';

export const getDebitCards = async (): Promise<any> => {
    return await api.get('/debit-card/list').then((response) => response.data);
};

export const getDebitCard = async (uid: string): Promise<any> => {
    return await api.get(`/debit-card/${uid}`).then((response) => response.data);
};

export const lockDebitCard = async (uid: string, lockReason: string): Promise<any> => {
    return await api
        .put(`/debit-card/${uid}/lock`, {
            lockReason
        })
        .then((response) => response.data);
};

export type ReissueReason = 'lost' | 'stolen' | 'damaged';

export const reissueDebitCard = async (uid: string, reissueReason: ReissueReason): Promise<any> => {
    return await api
        .put(`/debit-card/${uid}/reissue`, {
            reissueReason
        })
        .then((response) => response.data);
};

export const unlockDebitCard = async (uid: string): Promise<any> => {
    return await api.put(`/debit-card/${uid}/unlock`).then((response) => response.data);
};

export const activateDebitCard = async (
    uid: string,
    lastFourDigits: string,
    expiryDate: string,
    cvv: string
): Promise<any> => {
    return await api
        .put(`/debit-card/${uid}/activate`, {
            lastFourDigits,
            expiryDate,
            cvv
        })
        .then((response) => response.data);
};

export const getDebitCardToken = async (uid: string): Promise<any> => {
    return await api.get(`/debit-card/${uid}/pin_token`).then((response) => response.data);
};

export default {
    getDebitCards,
    getDebitCard,
    lockDebitCard,
    reissueDebitCard,
    unlockDebitCard,
    activateDebitCard,
    getDebitCardToken
};
