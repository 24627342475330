export enum PointType {
    Transaction = 'transaction',
    PreferredTransaction = 'preferred_transaction',
    LocalTransaction = 'local_transaction',
    MembershipBonus = 'membership_bonus',
    Referral = 'referral'
}

export const ALL_POINT_TYPES: Array<PointType> = [
    PointType.Transaction,
    PointType.PreferredTransaction,
    PointType.LocalTransaction,
    PointType.MembershipBonus,
    PointType.Referral
];

export interface Point {
    pointId: number;
    userId: string;
    name: string;
    transactionUid: string | null;
    usCentsAmount: number | null;
    pointType: PointType;
    count: number;
    dateCreated: Date;
}

export interface PointBreakdown {
    point_type: PointType;
    total: string;
}

export interface MarketTotal {
    name: string;
    market_code: string;
    total: number;
}

export const colorForPointType = (pointType: PointType): string => {
    switch (pointType) {
        case PointType.Transaction:
            return '#0a9d91';
        case PointType.PreferredTransaction:
            return '#b38b7d';
        case PointType.LocalTransaction:
            return '#e6bf73';
        case PointType.Referral:
            return '#a4a1a5';
        case PointType.MembershipBonus:
            return '#585858';
    }
};

export const nameForPointType = (pointType: PointType): string => {
    switch (pointType) {
        case PointType.Transaction:
            return 'Purchases';
        case PointType.PreferredTransaction:
            return 'Latitude Business Network';
        case PointType.LocalTransaction:
            return 'Local Purchases';
        case PointType.Referral:
            return 'Referrals';
        case PointType.MembershipBonus:
            return 'Membership Bonus';
    }
};

export const mapToPoint = (data: any): Point => {
    return {
        pointId: data.point_id,
        userId: data.user_id,
        name: data.name,
        transactionUid: data.transaction_uid,
        usCentsAmount: data.us_cents_amount,
        pointType: data.point_type,
        count: data.count,
        dateCreated: new Date(data.date_created)
    };
};
