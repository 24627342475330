import * as React from 'react';
import { StyleSheet, Image, ImageProps } from 'react-native';

const logoIcon = require('../assets/images/logo_icon.png');
const logoMedium = require('../assets/images/logo_medium.png');

export type LatLogoProps = Pick<
  ImageProps,
  Exclude<keyof ImageProps, 'source'>
>;

const defaultStyles = StyleSheet.create({
    icon: {
        width: 40,
        position: 'absolute',
    },
    medium: {
        width: 128,
        position: 'absolute',
    },
});

export const LatLogo = {
    Icon: (props: LatLogoProps): JSX.Element => {
        const { style, ...otherProps } = props;

        return (
            <Image
                source={logoIcon}
                style={[defaultStyles.icon, style]}
                resizeMode={'contain'}
                {...otherProps}
            />
        );
    },
    Medium: (props: LatLogoProps): JSX.Element => {
        const { style, ...otherProps } = props;

        return (
            <Image
                source={logoMedium}
                style={[defaultStyles.medium, style]}
                resizeMode={'contain'}
                {...otherProps}
            />
        );
    },
};
