export interface ComplianceDocument {
    uid?: string;
    documentUrl: string;
    eSignatureRequired: 'yes' | 'no';
    externalStorageName: string;
    name: string;
    step: number;
    version: number;
}

export const mapToComplianceDocument = (data: any): ComplianceDocument | undefined => {
    if (!data) {
        return undefined;
    }

    return {
        uid: data.uid,
        documentUrl: data.compliance_document_url,
        eSignatureRequired: data.electronic_signature_required,
        externalStorageName: data.external_storage_name,
        name: data.name,
        step: data.step,
        version: data.version
    };
};
