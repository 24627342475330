export enum DebitCardStatus {
    'closed',
    'closed_due_to_fraud',
    'initiated',
    'issued',
    'fraud_detected_by_system',
    'lost',
    'normal',
    'physical_card_production_temporarily_paused',
    'producing_physical_card',
    'shipment_returned',
    'shipped',
    'stolen',
    'usable_without_pin'
}

export interface DebitCard {
    uid: string;
    externalUid: string;
    poolUid: string;
    syntheticAccountUid: string;
    cardLastFourDigits?: string | null;
    status: DebitCardStatus;
    readyToUse: boolean;
    lockReason?: string | null;
    issuedOn?: Date | null;
    lockedAt?: Date | null;
    closedAt?: Date | null;
}

export const mapToDebitCard = (data: any): DebitCard | undefined => {
    if (!data) {
        return undefined;
    }

    return {
        uid: data.uid,
        externalUid: data.external_uid,
        poolUid: data.pool_uid,
        syntheticAccountUid: data.synthetic_account_uid,
        cardLastFourDigits: data.card_last_four_digits,
        status: data.status,
        readyToUse: data.ready_to_use,
        lockReason: data.lock_reason,
        issuedOn: data.issued_on,
        lockedAt: data.locked_at,
        closedAt: data.closed_at
    };
};
