const laBg = require('../assets/images/market/la-bg.png');
const chicagoBg = require('../assets/images/market/chicago-bg.png');
const miamiBg = require('../assets/images/market/miami-bg.png');
const dcBg = require('../assets/images/market/dc-bg.png');
const nycBg = require('../assets/images/market/nyc-bg.png');

const marketBgs = {
    la: laBg,
    chi: chicagoBg,
    mia: miamiBg,
    dc: dcBg,
    nyc: nycBg
};

export const marketHeroImages = {
    la: [
        require('../assets/images/hero/la/hero_00.png'),
        require('../assets/images/hero/la/hero_01.png'),
        require('../assets/images/hero/la/hero_02.png'),
        require('../assets/images/hero/la/hero_03.png'),
        require('../assets/images/hero/la/hero_04.png'),
        require('../assets/images/hero/la/hero_05.png')
    ],
    nyc: [
        require('../assets/images/hero/nyc/hero_00.png'),
        require('../assets/images/hero/nyc/hero_01.png'),
        require('../assets/images/hero/nyc/hero_02.png'),
        require('../assets/images/hero/nyc/hero_03.png'),
        require('../assets/images/hero/nyc/hero_04.png'),
        require('../assets/images/hero/nyc/hero_05.png')
    ]
};

export enum MarketCodes {
    'la',
    'nyc',
    'chi',
    'mia',
    'dc'
}

export interface Market {
    description?: string;
    displayIndex: number;
    isComingSoon: boolean;
    marketCode: keyof typeof MarketCodes;
    marketId: number;
    name: string;
    backgroundUrl: any;
}

export const mapToMarket = (data: any): Market | undefined => {
    if (!data) {
        return undefined;
    }

    const marketCode = data.market_code as keyof typeof MarketCodes;

    return {
        displayIndex: data.display_index,
        isComingSoon: data.is_coming_soon,
        marketCode: data.market_code,
        marketId: data.market_id,
        name: data.name,
        description: data.description,
        backgroundUrl: marketBgs[marketCode]
    };
};
