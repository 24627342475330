import * as React from 'react';
import { memo } from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg width={69} height={52} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            d="M52.988 52c8.254 0 13.866-6.642 13.866-14.093 0-8.424-6.438-13.122-12.38-13.122-1.816 0-3.467.324-5.117.972l-.66-.324c.66-5.67 6.602-16.523 20.303-23.49L68.01 0C48.696 5.022 36.81 19.115 36.81 34.019 36.811 44.549 43.084 52 52.988 52Zm-36.811 0c8.254 0 13.866-6.642 13.866-14.093 0-8.424-6.438-13.122-12.38-13.122-1.816 0-3.467.324-5.117.972l-.66-.324c.66-5.67 6.602-16.523 20.303-23.49L31.199 0C11.885 5.022 0 19.115 0 34.019 0 44.549 6.273 52 16.177 52Z"
            fill="#D3D3D3"
            fillOpacity={0.1}
        />
    </Svg>
);

const Memo = memo(SvgComponent);
export default Memo;
