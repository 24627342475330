export enum AccountType {
    ANY = 0,
    CHECKING = 1,
    SAVINGS = 2,
    LOAN = 3,
    CREDIT_CARD = 4,
    INVESTMENT = 5,
    LINE_OF_CREDIT = 6,
    MORTGAGE = 7,
    PROPERTY = 8,
    CASH = 9,
    INSURANCE = 10,
    PREPAID = 11
}

export interface MxAccount {
    account_number: string;
    account_number_set_by: number;
    account_number_set_by_name: string;
    account_subtype: number;
    account_subtype_name: string;
    account_subtype_set_by: number;
    account_subtype_set_by_name: string;
    account_type: AccountType;
    account_type_name: string;
    account_type_set_by: number;
    account_type_set_by_name: string;
    apr: null;
    apr_set_by: null;
    apr_set_by_name: null;
    apy: null;
    apy_set_by: null;
    apy_set_by_name: null;
    available_balance: number | null;
    available_balance_set_by: null;
    available_balance_set_by_name: null;
    available_credit: number;
    available_credit_set_by: number;
    available_credit_set_by_name: string;
    balance: number;
    balance_set_by: number;
    balance_set_by_name: string;
    calculated_apr: number;
    cash_balance: null;
    cash_balance_set_by: null;
    cash_balance_set_by_name: null;
    cash_surrender_value: null;
    cash_surrender_value_set_by: null;
    cash_surrender_value_set_by_name: null;
    created_at: string;
    credit_limit: null;
    credit_limit_set_by: null;
    credit_limit_set_by_name: null;
    currency_code: string;
    currency_code_set_by: number;
    currency_code_set_by_name: string;
    day_payment_is_due: null;
    day_payment_is_due_set_by: null;
    day_payment_is_due_set_by_name: null;
    death_benefit: null;
    death_benefit_set_by: null;
    death_benefit_set_by_name: null;
    external_guid: string;
    feed_account_number: string;
    feed_account_subtype: number;
    feed_account_subtype_name: string;
    feed_account_type: number;
    feed_account_type_name: string;
    feed_apr: null;
    feed_apy: null;
    feed_available_balance: null;
    feed_balance: number;
    feed_cash_balance: null;
    feed_cash_surrender_value: null;
    feed_credit_limit: null;
    feed_currency_code: string;
    feed_day_payment_is_due: null;
    feed_death_benefit: null;
    feed_holdings_value: null;
    feed_interest_rate: null;
    feed_last_payment: null;
    feed_last_payment_at: null;
    feed_loan_amount: null;
    feed_matures_on: null;
    feed_minimum_balance: null;
    feed_minimum_payment: null;
    feed_name: string;
    feed_nickname: null;
    feed_original_balance: null;
    feed_payment_due_at: string;
    feed_payoff_balance: null;
    feed_routing_number: null;
    feed_started_on: null;
    feed_statement_balance: null;
    feed_total_account_value: null;
    guid: string;
    holdings_value: null;
    holdings_value_set_by: null;
    holdings_value_set_by_name: null;
    institution_guid: string;
    insured_name: null;
    interest_rate: null;
    interest_rate_set_by: null;
    interest_rate_set_by_name: null;
    is_closed: boolean;
    is_hidden: boolean;
    is_manual: boolean;
    last_payment: null;
    last_payment_at: null;
    last_payment_at_set_by: null;
    last_payment_at_set_by_name: null;
    last_payment_set_by: null;
    last_payment_set_by_name: null;
    loan_amount: null;
    loan_amount_set_by: null;
    loan_amount_set_by_name: null;
    matures_on: null;
    matures_on_set_by: null;
    matures_on_set_by_name: null;
    member_guid: string;
    member_is_managed_by_user: boolean;
    metadata: null;
    minimum_balance: null;
    minimum_balance_set_by: null;
    minimum_balance_set_by_name: null;
    minimum_payment: null;
    minimum_payment_set_by: null;
    minimum_payment_set_by_name: null;
    name: string;
    name_set_by: number;
    name_set_by_name: string;
    nickname: null;
    nickname_set_by: null;
    nickname_set_by_name: null;
    original_balance: null;
    original_balance_set_by: null;
    original_balance_set_by_name: null;
    pay_out_amount: null;
    payment_due_at: string;
    payment_due_at_set_by: number;
    payment_due_at_set_by_name: string;
    payoff_balance: null;
    payoff_balance_set_by: null;
    payoff_balance_set_by_name: null;
    premium_amount: null;
    property_type: null;
    property_type_name: null;
    revision: number;
    routing_number: null;
    started_on: null;
    started_on_set_by: null;
    started_on_set_by_name: null;
    statement_balance: null;
    statement_balance_set_by: null;
    statement_balance_set_by_name: null;
    total_account_value: null;
    total_account_value_set_by: null;
    total_account_value_set_by_name: null;
    updated_at: string;
    user_guid: string;
    institution?: Institution; // set by client
}

export interface Institution {
    code: string;
    created_at: string;
    guid: string;
    has_checking_accounts: boolean;
    has_credit_card_accounts: boolean;
    has_investment_accounts: boolean;
    has_line_of_credit_accounts: boolean;
    has_loan_accounts: boolean;
    has_mortgage_accounts: boolean;
    has_savings_accounts: boolean;
    is_hidden: boolean;
    medium_logo_url: string;
    name: string;
    popularity: number;
    small_logo_url: string;
    supports_account_identification: boolean;
    supports_account_verification: boolean;
    supports_oauth: boolean;
    supports_transaction_history: boolean;
    updated_at: string;
    url: string;
}

export enum MxTransactionType {
    CREDIT = 1,
    DEBIT = 2
}

export enum MxTransactionStatus {
    POSTED = 1,
    PENDING = 2
}
export interface MxTransaction {
    account_guid: string;
    amount: number;
    amount_set_by: number;
    amount_set_by_name: string;
    category_guid: string;
    category_name: string;
    category_set_by: number;
    category_set_by_name: string;
    check_number: null;
    check_number_set_by: null;
    check_number_set_by_name: null;
    check_number_string: null;
    created_at: string;
    currency_code: string;
    currency_code_set_by: number;
    currency_code_set_by_name: string;
    date: string;
    date_set_by: number;
    date_set_by_name: string;
    description: string;
    description_set_by: number;
    description_set_by_name: string;
    external_guid: null;
    feed_amount: null;
    feed_check_number: null;
    feed_check_number_string: null;
    feed_currency_code: string;
    feed_description: string;
    feed_is_international: string;
    feed_latitude: null;
    feed_longitude: null;
    feed_memo: null;
    feed_posted_at: null;
    feed_posted_on: null;
    feed_status: null;
    feed_transacted_at: null;
    feed_transacted_on: null;
    feed_transaction_type: null;
    feed_transaction_type_name: null;
    guid: string;
    has_been_split: boolean;
    has_been_viewed: boolean;
    is_bill_pay: boolean;
    is_direct_deposit: boolean;
    is_expense: boolean;
    is_fee: boolean;
    is_flagged: boolean;
    is_hidden: boolean;
    is_income: boolean;
    is_international: boolean;
    is_international_set_by: number;
    is_international_set_by_name: string;
    is_overdraft_fee: boolean;
    is_payroll_advance: boolean;
    is_recurring: boolean;
    is_subscription: boolean;
    latitude: null;
    latitude_set_by: null;
    latitude_set_by_name: null;
    localized_description: null;
    localized_memo: null;
    longitude: null;
    longitude_set_by: null;
    longitude_set_by_name: null;
    member_is_managed_by_user: boolean;
    memo: string;
    memo_set_by: number;
    memo_set_by_name: string;
    merchant_category_code: null;
    merchant_guid: null;
    merchant_location_guid: null;
    metadata: string;
    parent_guid: null;
    posted_at: null;
    posted_at_set_by: null;
    posted_at_set_by_name: null;
    revision: number;
    scheduled_payment_guid: null;
    status: MxTransactionStatus;
    status_name: null;
    status_set_by: number;
    status_set_by_name: string;
    top_level_category_guid: string;
    transacted_at: null;
    transacted_at_set_by: null;
    transacted_at_set_by_name: null;
    transaction_type: MxTransactionType;
    transaction_type_name: string;
    transaction_type_set_by: number;
    transaction_type_set_by_name: string;
    updated_at: string;
    user_guid: string;
}

export interface MxMerchant {
    created_at: string;
    guid: string;
    logo_updated_at: null;
    logo_url: string;
    name: string;
    updated_at: string;
    website_url: string;
}

// Latitude Models
export interface MonthlyAverage {
    current: number;
    average: number;
}

export interface MonthlySpending {
    averageTotal: number;
    averages: MonthlyAverage[];
    monthlyTotal: number;
}

export function isCreditAccount(account: MxAccount): boolean {
    // Credit account includes Credit Cards, Loans, Mortages, Line of Credit
    return [
        AccountType.LOAN,
        AccountType.CREDIT_CARD,
        AccountType.LINE_OF_CREDIT,
        AccountType.MORTGAGE
    ].includes(account.account_type);
}

export function isLiquid(account: MxAccount): boolean {
    // Liquid assets include cash and stocks
    return [
        AccountType.CHECKING,
        AccountType.SAVINGS,
        AccountType.INVESTMENT,
        AccountType.CASH
    ].includes(account.account_type);
}
