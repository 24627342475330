import { Charity, mapToCharity } from './Charity';
import { mapToMarket, Market } from './Market';
import { mapToPromoCode, PromoCode } from './PromoCode';

export enum LatitudeUserStatuses {
    Active = 'active',
    Archived = 'archived',
    Initiated = 'initiated',
    ManualReview = 'manual_review',
    Queued = 'queued',
    Rejected = 'rejected',
    IdentityVerified = 'identity_verified',
    UnderReview = 'under_review'
}

export enum LatitudeUserKycStatuses {
    Approved = 'approved',
    Denied = 'denied',
    DocumentsProvided = 'documents_provided',
    DocumentsRejected = 'documents_rejected',
    ManualReview = 'manual_review',
    PendingDocuments = 'pending_documents',
    ReadyForCustodialPartnerReview = 'ready_for_custodial_partner_review',
    UnderReview = 'under_review',
    PendingIdDocuments = 'pending_ID_documents',
    PendingPoaDocuments = 'pending_poa_documents',
    PendingIdAndPoaDocuments = 'pending_idandpoa_documents'
}

export interface LatitudeUserAddress {
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
}

export interface LatitudeUser {
    email?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    phone?: string;
    dob?: string;
    totalBalance?: number;
    status?: LatitudeUserStatuses;
    kycStatus?: LatitudeUserKycStatuses;
    createdAt?: Date;
    lockedAt?: Date;
    lockReason?: string;
    address?: LatitudeUserAddress;
    market?: Market;
    charity?: Charity;
    isOnboarded?: boolean;
    promoCode?: PromoCode;
    refillThresholdCents: number | null;
    refillAmountCents: number;
}

export const mapToLatitudeUser = (data: any): LatitudeUser | undefined => {
    if (!data) {
        return undefined;
    }

    return {
        email: data.email,
        firstName: data.details?.first_name,
        middleName: data.details?.middle_name,
        lastName: data.details?.last_name,
        phone: data.details?.phone,
        dob: data.details?.dob,
        totalBalance: data.total_balance,
        status: data.status,
        kycStatus: data.kyc_status,
        createdAt: new Date(data.created_at),
        lockedAt: data.locked_at ? new Date(data.locked_at) : undefined,
        lockReason: data.lock_reason,
        address: {
            street1: data.details?.address?.street1,
            street2: data.details?.address?.street2,
            city: data.details?.address?.city,
            state: data.details?.address?.state,
            postalCode: data.details?.address?.postal_code
        },
        market: mapToMarket(data.market),
        isOnboarded: data.isOnboarded,
        charity: mapToCharity(data.charity),
        promoCode: mapToPromoCode(data.promoCode),
        refillThresholdCents: data.refill_threshold_cents,
        refillAmountCents: data.refill_amount_cents
    };
};
