import { applicationId } from 'expo-application';

const uniquedKey = (key: string) => {
    return `${applicationId}-${key}`;
};

export default {
    REMEMBERED_EMAIL: uniquedKey('remembered_email'),
    HERO_BACKGROUND_INDEX: uniquedKey('hero_background_index'),
    BACKGROUND_TIMESTAMP: uniquedKey('background_timestamp'),
    SESSION_TOKEN: uniquedKey('auth_payload'),
    ONBOARDING_DATA: uniquedKey('onboarding_data'),
    ONBOARDING_DATA_TIMESTAMPS: uniquedKey('onboarding_data_timestamps'),
    BIOMETRIC_AUTH_DATA: uniquedKey('biometric_auth_data'),
    DISABLE_BIOMETRIC_PROMPT_AFTER_LOGIN: uniquedKey('disable_biometric_prompt_after_login'),
    DEMO_MODE_ENABLED: uniquedKey('demo_mode'),
    CURRENT_PUSH_TOKEN: uniquedKey('push_token'),
    LAST_NOTIFICATION_REQUEST: uniquedKey('last_notification_request'),
    PASSWORD: uniquedKey('password')
};
