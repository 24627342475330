import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg width={20} height={20} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path
            d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0Zm0 1a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm1.156 6.72v8.3c0 .56.22.72 1.4.74V17h-5.5v-.24c1.14-.02 1.44-.18 1.44-.74V9.14c0-.6-.3-.98-1.44-1V7.9l4.1-.18Zm-1.38-4.7c.94 0 1.56.62 1.56 1.56 0 .98-.62 1.6-1.56 1.6-.94 0-1.58-.56-1.58-1.54 0-.96.64-1.62 1.58-1.62Z"
            fill="#9C9C9C"
            fillRule="evenodd"
        />
    </Svg>
);

export default SvgComponent;
