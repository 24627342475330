import LatDropdown, { LatDropdownItem } from '@latitude/components/LatDropdown';
import LatModal from '@latitude/components/LatModal';
import { useThemeColor } from '@latitude/components/Themed';
import { Market } from '@latitude/models';
import CustomerService from '@latitude/services/CustomerService';
import { useMarket } from '@latitude/state';
import { Info } from '@latitude/svg';
import { track } from '@latitude/utils/analytics/index';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useMemo, useRef, useState } from 'react';
import {
    KeyboardAvoidingView,
    Platform,
    ScrollView,
    StyleSheet,
    TextInput,
    TouchableOpacity,
    View
} from 'react-native';
import { useMediaQuery } from 'react-responsive';
import validator from 'validator';
import LatButton from '../components/LatButton';
import { LatCol } from '../components/LatCol';
import { LatInput } from '../components/LatInput';
import { LatRow } from '../components/LatRow';
import { LatScreen } from '../components/LatScreen';
import OnboardingBg from '../components/OnboardingBg';
import { Body, Heading2, Label, TextLinkButton, Title } from '../components/StyledText';
import useIsMountedRef from '../hooks/useIsMountedRef';
import { RootStackParamList } from '../types';

type RequestInviteErrors = {
    name?: string;
    email?: string;
    planPreference?: string;
    community?: string;
    about?: string;
    common?: string;
};

interface NavigationProps {
    navigation: StackNavigationProp<RootStackParamList, 'RequestInvite'>;
}

const RequestInviteScreen = ({ navigation }: NavigationProps): JSX.Element => {
    const { markets } = useMarket();
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [about, setAbout] = useState<string>('');
    const [errors, setErrors] = useState<RequestInviteErrors>({});
    const [marketIndex, setMarketIndex] = useState<number>();
    const [isCommunityDropdownOpen, setIsCommunityDropdownOpen] = useState<boolean>(false);
    const [communityModalVisible, setCommunityModalVisible] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const emailInputRef = useRef<TextInput>(null);
    const aboutInputRef = useRef<TextInput>(null);
    const linkedinInputRef = useRef<TextInput>(null);

    const isMountedRef = useIsMountedRef();
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const light = useThemeColor('light');

    const styles = StyleSheet.create({
        scrollViewContentContainerStyle: {
            flexGrow: 1,
            justifyContent: 'center'
        },
        instructionsRow: {
            marginBottom: 12
        },
        formGroup: {
            width: isDesktop ? '47%' : '100%'
        },
        formRow: {
            marginBottom: 12
        },
        aboutInput: {
            // Multiline textinput has a bug where paddingHorizontal doesn't work
            paddingTop: 12,
            paddingBottom: 12,
            height: 120
        },
        submitButtonRow: {
            alignItems: 'flex-end',
            marginTop: isDesktop ? 48 : 0,
            marginBottom: isDesktop ? 48 : 24,
            width: isDesktop ? 400 : '100%',
            justifyContent: 'center'
        },
        rightAlignColumn: {
            justifyContent: 'center',
            alignItems: 'flex-end'
        }
    });

    const activeMarkets = useMemo(() => {
        return markets.filter((m: Market) => m.isComingSoon === false);
    }, [markets]);

    const onNameSubmit = (): void => {
        if (validateName()) {
            emailInputRef.current?.focus();
        }
    };

    const onEmailSubmit = (): void => {
        if (validateEmail()) {
            linkedinInputRef.current?.focus();
        }
    };

    const validateName = (text = name): boolean => {
        let nameError = '';

        if (validator.isEmpty(text)) {
            nameError = 'Name is required';
        }

        setErrors((prevState) => ({ ...prevState, name: nameError }));

        return !nameError;
    };

    const validateEmail = (text = email): boolean => {
        let emailError = '';

        if (validator.isEmpty(text)) {
            emailError = 'Email is required';
        } else if (!validator.isEmail(text)) {
            emailError = 'Invalid email address';
        }

        setErrors((prevState) => ({ ...prevState, email: emailError }));

        return !emailError;
    };

    const validateAbout = (text = about): boolean => {
        let aboutError = '';

        if (validator.isEmpty(text)) {
            aboutError = 'Tell us more is required';
        }

        setErrors((prevState) => ({ ...prevState, about: aboutError }));

        return !aboutError;
    };

    const validateCommunity = (index = marketIndex): boolean => {
        let communityError = '';

        if (index === undefined) {
            communityError = 'Plan preference is required';
        }

        setErrors((prevState) => ({ ...prevState, community: communityError }));

        return !communityError;
    };

    const hasInputErrors = (): boolean => {
        return !validateName() || !validateEmail() || !validateCommunity() || !validateAbout();
    };

    const submit = async (): Promise<void> => {
        setErrors({});

        if (hasInputErrors()) {
            return;
        }

        setIsProcessing(true);

        try {
            const market = activeMarkets[marketIndex ?? 0];
            track('click request invite', {
                market: market.name
            });
            // Call endpoint for requesting invite
            await CustomerService.requestInviteCode({
                name,
                email,
                market_id: market.marketId,
                about
            });
            navigation.navigate('InvitePending');
        } catch (err) {
            if (err && err.response && err.response.status === 400) {
                setErrors({
                    ...errors,
                    common: 'Unable to request an invite'
                });
            } else {
                throw err;
            }
        } finally {
            if (isMountedRef.current) {
                setIsProcessing(false);
            }
        }
    };

    const isSubmitDisable = (): boolean => {
        return !!errors.email && !!errors.name && !!errors.about;
    };

    const onPressAlreadyHaveAccount = (): void => {
        navigation.navigate('Login');
    };

    const onCloseModal = (): void => {
        setCommunityModalVisible(false);
    };

    const RequestHeader = useMemo(() => {
        if (isDesktop) {
            return (
                <>
                    <LatRow>
                        <LatCol style={{ alignItems: 'center' }}>
                            <Label
                                style={{
                                    letterSpacing: 3,
                                    textTransform: 'uppercase',
                                    fontSize: 14,
                                    color: light,
                                    marginTop: 48
                                }}
                            >
                                Get Latitude
                            </Label>
                            <Title style={{ maxWidth: 696, marginTop: 32 }} textAlign="center">
                                Join Our Community
                            </Title>
                            <Body style={{ fontSize: 18, marginTop: 24 }} textAlign="center">
                                We are focused on uniting a thoughtful selection of individuals to
                                connect, learn and think about their financial and community impact.
                                To join Latitude you must either be invited by an existing member or
                                request an invite below
                            </Body>
                        </LatCol>
                    </LatRow>
                    <LatRow>
                        <LatCol>
                            <TextLinkButton
                                onPress={() => {
                                    navigation.navigate('Membership');
                                }}
                                textAlign="center"
                                testID="view-plans"
                            >
                                View Membership Plan
                            </TextLinkButton>
                        </LatCol>
                    </LatRow>
                </>
            );
        } else {
            return (
                <>
                    <LatRow style={{ marginTop: 24 }}>
                        <LatCol>
                            <Heading2 textAlign="center">Request Invite</Heading2>
                        </LatCol>
                    </LatRow>
                    <LatRow justifyContent="center" style={styles.instructionsRow}>
                        <LatCol size={4} style={{ paddingHorizontal: 32 }}>
                            <Body fontWeight="bold" textAlign="center">
                                To gain membership in Latitude you must either be invited by an
                                existing member or make a request via the below.
                            </Body>
                        </LatCol>
                    </LatRow>
                    <LatRow>
                        <LatCol>
                            <TextLinkButton
                                onPress={() => {
                                    navigation.navigate('Membership');
                                }}
                                textAlign="center"
                                testID="view-plans"
                            >
                                View Membership Plan
                            </TextLinkButton>
                        </LatCol>
                    </LatRow>
                </>
            );
        }
    }, [isDesktop]);

    return (
        <LatScreen>
            <KeyboardAvoidingView
                behavior="padding"
                style={{ flex: 1 }}
                keyboardVerticalOffset={84}
            >
                <OnboardingBg />
                <ScrollView
                    bounces={false}
                    showsVerticalScrollIndicator={false}
                    keyboardShouldPersistTaps="handled"
                    contentContainerStyle={styles.scrollViewContentContainerStyle}
                >
                    <View style={{ flex: 1 }} />
                    {RequestHeader}
                    <View
                        style={{
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <View style={styles.formGroup}>
                            <LatRow>
                                <LatCol>
                                    <Label colorScheme="light">Full Name</Label>
                                </LatCol>
                            </LatRow>
                            <LatRow style={styles.formRow}>
                                <LatCol>
                                    <LatInput
                                        value={name}
                                        textContentType={'name'}
                                        placeholder="Enter Your Full Name"
                                        autoCapitalize={'words'}
                                        onSubmitEditing={onNameSubmit}
                                        onChangeText={setName}
                                        onBlur={(): boolean => validateName()}
                                        returnKeyType={'next'}
                                        blurOnSubmit={false}
                                        error={!!errors.name}
                                        errorText={errors.name}
                                        editable={!isProcessing}
                                        testID="name"
                                    />
                                </LatCol>
                            </LatRow>
                        </View>
                        <View style={styles.formGroup}>
                            <LatRow>
                                <LatCol>
                                    <Label colorScheme="light">Email</Label>
                                </LatCol>
                            </LatRow>
                            <LatRow style={styles.formRow}>
                                <LatCol>
                                    <LatInput
                                        textInputRef={emailInputRef}
                                        value={email}
                                        textContentType={'emailAddress'}
                                        placeholder="Enter Your Email"
                                        autoCapitalize={'none'}
                                        keyboardType={'email-address'}
                                        onSubmitEditing={onEmailSubmit}
                                        onChangeText={setEmail}
                                        onBlur={(): boolean => validateEmail()}
                                        returnKeyType={'next'}
                                        blurOnSubmit={false}
                                        error={!!errors.email}
                                        errorText={errors.email}
                                        editable={!isProcessing}
                                        testID="email"
                                    />
                                </LatCol>
                            </LatRow>
                        </View>
                        <View style={[styles.formGroup, { zIndex: -1 }]}>
                            <LatRow>
                                <LatCol>
                                    <Label colorScheme="light">
                                        Choose your preferred Latitude Community
                                    </Label>
                                </LatCol>
                                <LatCol style={[styles.rightAlignColumn, { flex: 0 }]}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            setCommunityModalVisible(true);
                                        }}
                                    >
                                        <Info />
                                    </TouchableOpacity>
                                </LatCol>
                            </LatRow>
                            <LatRow style={[styles.formRow]}>
                                <LatCol>
                                    <LatDropdown
                                        placeholder="Your Community"
                                        selectedIndex={marketIndex}
                                        items={activeMarkets.map(
                                            (m) =>
                                                ({
                                                    text: m.name,
                                                    value: m.name
                                                } as LatDropdownItem)
                                        )}
                                        onChange={(item, index) => {
                                            setMarketIndex(index);
                                            setErrors({ ...errors, community: '' });
                                        }}
                                        isOpen={isCommunityDropdownOpen}
                                        onChangeIsOpen={setIsCommunityDropdownOpen}
                                        error={!!errors.community}
                                        disabled={isProcessing}
                                        testID="market"
                                    />
                                </LatCol>
                            </LatRow>
                        </View>
                    </View>
                    <LatRow style={{ zIndex: -1 }}>
                        <LatCol>
                            <Label colorScheme="light">Tell Us More</Label>
                        </LatCol>
                        <LatCol>
                            <Label textAlign="right" colorScheme="light">
                                {256 - about.length} Characters Remaining
                            </Label>
                        </LatCol>
                    </LatRow>
                    <LatRow style={[styles.formRow, { zIndex: -1 }]}>
                        <LatCol>
                            <LatInput
                                textInputRef={aboutInputRef}
                                value={about}
                                placeholder="Why Latitude? What does community mean to you?"
                                autoCapitalize={'sentences'}
                                onChangeText={setAbout}
                                onBlur={(): boolean => validateAbout()}
                                maxLength={256}
                                blurOnSubmit={false}
                                error={!!errors.about}
                                errorText={errors.about}
                                editable={!isProcessing}
                                multiline
                                style={styles.aboutInput}
                                testID="tell-us-more"
                            />
                        </LatCol>
                    </LatRow>
                    {!!errors.common && (
                        <LatRow justifyContent="center" style={{ zIndex: -1 }}>
                            <LatCol>
                                <Body color="error" textAlign="center">
                                    {errors.common}
                                </Body>
                            </LatCol>
                        </LatRow>
                    )}
                    <View style={{ alignItems: 'center', zIndex: -1 }}>
                        <LatRow style={styles.submitButtonRow}>
                            <LatCol>
                                <LatButton.Primary
                                    title="Request An Invite"
                                    onPress={submit}
                                    disabled={isSubmitDisable() || isProcessing}
                                    forceShowPressedState={isProcessing}
                                    testID="request"
                                />
                            </LatCol>
                        </LatRow>
                        {/* Only show already a member link in mobile */}
                        {Platform.OS !== 'web' && (
                            <LatRow justifyContent="center">
                                <TextLinkButton
                                    onPress={onPressAlreadyHaveAccount}
                                    disabled={isProcessing}
                                >
                                    Already a member?
                                </TextLinkButton>
                            </LatRow>
                        )}
                    </View>
                    <View style={{ flex: 1 }} />
                </ScrollView>
            </KeyboardAvoidingView>
            <LatModal
                title="Latitude Communities"
                visible={communityModalVisible}
                buttons={[
                    {
                        type: 'Secondary',
                        text: 'Close',
                        onPress: onCloseModal
                    }
                ]}
                onClose={onCloseModal}
            >
                <LatRow style={{ paddingTop: 16 }}>
                    <LatCol style={{ flex: 1 }}>
                        <Body style={{ textAlign: 'center' }}>
                            Latitude tailors its products, services and philanthropy to select
                            communities. Nevertheless, we do not require our members to live in
                            these communities as they can still make an impact and benefit from our
                            offerings living elsewhere.
                        </Body>
                    </LatCol>
                </LatRow>
            </LatModal>
        </LatScreen>
    );
};

export default RequestInviteScreen;
