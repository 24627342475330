import CharityService from '@latitude/services/CharityService';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useContext } from 'react';
import { AppState, AppStateStatus } from 'react-native';
import { mapToLatitudeUser } from '../models';
import { ComplianceDocument, mapToComplianceDocument } from '../models/ComplianceDocument';
import { HomeAddress, OnboardingData, PersonalInfo, PiiData } from '../models/OnboardingData';
import ComplianceService from '../services/ComplianceService';
import CustomerService from '../services/CustomerService';
import UserMarketService from '../services/UserMarketService';
import { RootStackParamList } from '../types';
import { AuthContext } from './Auth';

export type ComplianceDocumentSelection = ComplianceDocument & {
    selected?: boolean;
    alreadyAccepted: boolean;
};

export type OnboardingContextProps = {
    isLoading: boolean;
    workflowUid?: string;
    currentScreen?: keyof RootStackParamList;
    termsAndConditions: ComplianceDocumentSelection[];
    patriotActDocument?: ComplianceDocumentSelection;
    piiData?: PiiData;
    isNewUser?: boolean;
    bankingDisclosures: ComplianceDocumentSelection[];
    onboardingNotFinished: boolean;
    setOnboardingNotFinished: (value: boolean) => Promise<void>;
    setCurrentScreen: (screenName: keyof RootStackParamList) => Promise<void>;
    setTermsAndConditions: (termsAndConditions: ComplianceDocumentSelection[]) => Promise<void>;
    acceptAllTerms: () => Promise<void>;
    acceptPatriotAct: () => Promise<void>;
    acceptAllBankingDisclosures: () => Promise<void>;
    loadPatriotActDocuments: () => Promise<void>;
    saveSelectedMarket: (marketCode: string) => Promise<void>;
    saveSelectedCharity: (charityId: number) => Promise<void>;
    savePersonalInfo: (personalInfo: PersonalInfo) => Promise<void>;
    saveHomeAddress: (addressInfo: HomeAddress) => Promise<void>;
    saveDateOfBirth: (dateOfBirth: string) => Promise<void>;
    saveSSN: (identityInfo: string) => Promise<void>;
    savePii: () => Promise<void>;
    setBankingDisclosures: (bankingDisclosures: ComplianceDocumentSelection[]) => Promise<void>;
    loadBankingDisclosures: () => Promise<void>;
    submitApplication: () => Promise<void>;
    updateIsNewUser?: (value: boolean) => void;
    cleanUpOnboarding?: () => void;
};

export const OnboardingContext = React.createContext<OnboardingContextProps>({
    isLoading: false,
    termsAndConditions: [],
    bankingDisclosures: [],
    onboardingNotFinished: false,
    setOnboardingNotFinished: () => Promise.resolve(),
    setCurrentScreen: () => Promise.resolve(),
    setTermsAndConditions: () => Promise.resolve(),
    acceptAllTerms: () => Promise.resolve(),
    acceptPatriotAct: () => Promise.resolve(),
    acceptAllBankingDisclosures: () => Promise.resolve(),
    loadPatriotActDocuments: () => Promise.resolve(),
    saveSelectedCharity: () => Promise.resolve(),
    saveSelectedMarket: () => Promise.resolve(),
    savePersonalInfo: () => Promise.resolve(),
    saveHomeAddress: () => Promise.resolve(),
    saveDateOfBirth: () => Promise.resolve(),
    saveSSN: () => Promise.resolve(),
    savePii: () => Promise.resolve(),
    setBankingDisclosures: () => Promise.resolve(),
    loadBankingDisclosures: () => Promise.resolve(),
    submitApplication: () => Promise.resolve()
});

export interface OnboardingProviderProps {
    navigation: StackNavigationProp<RootStackParamList>;
    children?: JSX.Element;
}

export type OnboardingProviderState = {
    isLoading: boolean;
    workflowUid?: string;
    currentScreen?: keyof RootStackParamList;
    termsAndConditions: ComplianceDocumentSelection[];
    patriotActDocument?: ComplianceDocumentSelection;
    piiData?: PiiData;
    bankingDisclosures: ComplianceDocumentSelection[];
    onboardingNotFinished: boolean;
    isNewUser: boolean;
    appState: AppStateStatus;
};

const initialState = {
    isLoading: false,
    workflowUid: undefined,
    currentScreen: undefined,
    termsAndConditions: [],
    patriotActDocument: undefined,
    piiData: undefined,
    bankingDisclosures: [],
    onboardingNotFinished: false,
    isNewUser: false,
    appState: AppState.currentState
};

const steps: (keyof RootStackParamList)[] = [
    'MarketSelection',
    'Subscription',
    'Charity',
    'TermsAndConditions',
    'PatriotAct',
    'UserInformation',
    'HomeAddress',
    'DateOfBirth',
    'SSN',
    'ReviewPersonalInfo',
    'BankingDisclosures'
];
export class OnboardingProvider extends React.Component<
    OnboardingProviderProps,
    OnboardingProviderState
> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    encodedEmail?: string;
    _isMounted: boolean;

    constructor(props: OnboardingProviderProps) {
        super(props);
        this._isMounted = false;
        this.state = initialState;
    }

    async componentDidMount(): Promise<void> {
        this._isMounted = true;

        this.refreshOnboarding();
        await this.refreshPii();
    }

    componentWillUnmount(): void {
        this._isMounted = false;
    }

    refreshPii = async () => {
        const customer = await CustomerService.getCustomer().then((response) => {
            return mapToLatitudeUser(response.data);
        });
        if (customer) {
            this.setState({
                piiData: {
                    firstName: customer.firstName ?? '',
                    middleName: customer.middleName,
                    lastName: customer.lastName ?? '',
                    mobileNumber: customer.phone ?? '',
                    dob: customer.dob ?? '',
                    address: {
                        streetName: customer.address?.street1 ?? '',
                        aptSuiteName: customer.address?.street2,
                        city: customer.address?.city ?? '',
                        state: customer.address?.state ?? '',
                        zipCode: customer.address?.postalCode ?? ''
                    }
                }
            });
        }
    };

    refreshOnboarding = (): void => {
        // We need to fetch documents first to ensure we are on the same compliance workflow
        this.loadTermsDocuments()
            .then(async (): Promise<void> => {
                // Store workflow uid to check when refreshing
                await this.setOnboardingData({ workflowUid: this.state.workflowUid });
            })
            .then((): void => {
                if (this.context.userProfile?.status === 'initiated') {
                    const currentScreen = this.state.currentScreen;
                    const navigation = this.props.navigation;

                    if (currentScreen && steps.includes(currentScreen)) {
                        for (const step of steps) {
                            navigation.navigate(step);

                            if (step === currentScreen) {
                                break;
                            }
                        }
                    }
                }
            });
    };

    promiseSetState = async <K extends keyof OnboardingProviderState>(
        state:
            | Pick<OnboardingProviderState, K>
            | ((
                  prevState: Readonly<OnboardingProviderState>,
                  props: Readonly<OnboardingProviderProps>
              ) => Pick<OnboardingProviderState, K> | OnboardingProviderState | null)
            | null
    ): Promise<void> => {
        if (this._isMounted) {
            return new Promise((resolve) => {
                this.setState(state, () => {
                    resolve(null);
                });
            });
        }
    };

    setOnboardingNotFinished = async (value: boolean): Promise<void> => {
        this.updateIsNewUser(true);
    };

    setTermsAndConditions = async (
        termsAndConditions: ComplianceDocumentSelection[]
    ): Promise<void> => {
        await this.promiseSetState({ termsAndConditions });
    };

    setBankingDisclosures = async (
        bankingDisclosures: ComplianceDocumentSelection[]
    ): Promise<void> => {
        await this.promiseSetState({ bankingDisclosures });
    };

    getComplianceDocuments = async (): Promise<ComplianceDocument[][]> => {
        const response = await ComplianceService.getComplianceDocuments();
        const all = response.data.all_documents.map((x) =>
            mapToComplianceDocument(x)
        ) as ComplianceDocument[];
        const accepted = response.data.accepted_documents.map((x) =>
            mapToComplianceDocument(x)
        ) as ComplianceDocument[];
        const pending = response.data.current_step_documents_pending.map((x) =>
            mapToComplianceDocument(x)
        ) as ComplianceDocument[];

        return [all, accepted, pending];
    };

    loadPatriotActDocuments = async (): Promise<void> => {
        this.setState({ isLoading: true });

        const [, accepted, pending] = await this.getComplianceDocuments();

        const acceptedPatriotAct = accepted.find((acc) => acc.name === 'USA PATRIOT Act');
        const pendingPatriotAct = pending.find((acc) => acc.name === 'USA PATRIOT Act');
        const pA = acceptedPatriotAct || pendingPatriotAct;

        const p = {
            ...pA,
            alreadyAccepted: !!acceptedPatriotAct
        };

        this.setState({ patriotActDocument: p, isLoading: false });
    };

    loadTermsDocuments = async (): Promise<void> => {
        this.setState({ isLoading: true });
        const response = await ComplianceService.getComplianceDocuments();

        const [all, accepted, pending] = await this.getComplianceDocuments();

        const acceptedTerms = accepted.filter((x) => x.step === 1);
        const pendingTerms = pending.filter((x) => x.step === 1);
        const allTerms = all
            .filter((x) => x.step === 1)
            .map((x) => {
                // all_documents don't have UIDs included, we need to get them from here
                const acceptedTerm = acceptedTerms.find((acc) => acc.name === x.name);
                const pendingTerm = pendingTerms.find((acc) => acc.name === x.name);

                return {
                    ...x,
                    selected: !!acceptedTerm,
                    uid: acceptedTerm?.uid ?? pendingTerm.uid,
                    alreadyAccepted: !!acceptedTerm
                } as ComplianceDocumentSelection;
            });

        if (this._isMounted) {
            await this.promiseSetState({ termsAndConditions: allTerms });
            this.setState({ workflowUid: response.data.uid, isLoading: false });
        }
    };

    loadBankingDisclosures = async (): Promise<void> => {
        this.setState({ isLoading: true });

        const [all, accepted, pending] = await this.getComplianceDocuments();

        const acceptedDisclosures = accepted.filter((x) => x.step === 2);
        const pendingDisclosures = pending.filter((x) => x.step === 2);
        const allDisclosures = all
            .filter((x) => x.step === 2)
            .map((x) => {
                // all_documents don't have UIDs included, we need to get them from here
                const acceptedDisclosure = acceptedDisclosures.find((acc) => acc.name === x.name);
                const pendingDisclosure = pendingDisclosures.find((acc) => acc.name === x.name);

                return {
                    ...x,
                    selected: !!acceptedDisclosure,
                    uid: acceptedDisclosure?.uid ?? pendingDisclosure.uid,
                    alreadyAccepted: !!acceptedDisclosure
                } as ComplianceDocumentSelection;
            });

        if (this._isMounted) {
            const bankingDisclosures = allDisclosures.filter(
                (d) => !d.name.toLowerCase().includes('patriot')
            );
            await this.promiseSetState({ bankingDisclosures });

            this.setState({ isLoading: false });
        }
    };

    acceptPatriotAct = async (): Promise<void> => {
        const { patriotActDocument: patriotAct } = this.state;
        this.setState({ isLoading: true });

        if (patriotAct && !patriotAct?.alreadyAccepted) {
            const response = await ComplianceService.acknowledgeComplianceDocument(
                this.state.workflowUid,
                patriotAct.uid,
                patriotAct.eSignatureRequired === 'yes'
                    ? this.context.userProfile.email // Temporary. TODO: Determine how to handle e-signatures
                    : undefined
            );

            if (!response.success) {
                throw new Error(`Failed to acknowledge ${patriotAct.name}`);
            }
        }

        this.setState({
            isLoading: false,
            patriotActDocument: { ...patriotAct, alreadyAccepted: true }
        });
    };

    acceptAllTerms = async (): Promise<void> => {
        this.setState({ isLoading: true });

        try {
            const { termsAndConditions } = this.state;
            for (const term of termsAndConditions) {
                if (!term.alreadyAccepted) {
                    const response = await ComplianceService.acknowledgeComplianceDocument(
                        this.state.workflowUid,
                        term.uid,
                        term.eSignatureRequired === 'yes'
                            ? this.context.userProfile.email // Temporary. TODO: Determine how to handle e-signatures
                            : undefined
                    );
                    if (!response.success) {
                        throw new Error(`Failed to acknowledge ${term.name}`);
                    }

                    await this.promiseSetState((oldTerms) => {
                        const newTerms = oldTerms.termsAndConditions.map((x, i) =>
                            i === oldTerms.termsAndConditions.indexOf(term)
                                ? { ...x, alreadyAccepted: true }
                                : x
                        );
                        return { termsAndConditions: newTerms };
                    });
                }
            }
        } finally {
            this.setState({ isLoading: false });
        }
    };

    acceptAllBankingDisclosures = async (): Promise<void> => {
        this.setState({ isLoading: true });

        try {
            const { bankingDisclosures } = this.state;
            for (const doc of bankingDisclosures) {
                if (!doc.alreadyAccepted) {
                    const response = await ComplianceService.acknowledgeComplianceDocument(
                        this.state.workflowUid,
                        doc.uid,
                        doc.eSignatureRequired === 'yes'
                            ? this.context.userProfile?.email // Temporary. TODO: Determine how to handle e-signatures
                            : undefined
                    );
                    if (!response.success) {
                        throw new Error(`Failed to acknowledge ${doc.name}`);
                    }

                    await this.promiseSetState((oldDisclosures) => {
                        const newDisclosures = oldDisclosures.bankingDisclosures.map((x, i) =>
                            i === oldDisclosures.bankingDisclosures.indexOf(doc)
                                ? { ...x, alreadyAccepted: true }
                                : x
                        );
                        return { bankingDisclosures: newDisclosures };
                    });
                }
            }
        } finally {
            this.setState({ isLoading: false });
        }
    };

    setOnboardingData = async (newData: Partial<OnboardingData>): Promise<void> => {
        this.setState({
            ...this.state,
            ...newData
        });
    };

    saveSelectedMarket = async (marketCode: string): Promise<void> => {
        if (!this._isMounted) {
            return;
        }
        const existingData = this.state;
        await this.setOnboardingData({
            piiData: {
                ...existingData?.piiData,
                marketCode
            }
        });
    };

    saveSelectedCharity = async (charityId: number): Promise<void> => {
        if (!this._isMounted) {
            return;
        }
        const existingData = this.state;
        await this.setOnboardingData({
            piiData: {
                ...existingData?.piiData,
                charityId
            }
        });
    };

    savePersonalInfo = async (personalInfo: PersonalInfo): Promise<void> => {
        const existingData = this.state;
        await this.setOnboardingData({
            piiData: {
                ...existingData?.piiData,
                ...personalInfo
            }
        });
    };

    saveHomeAddress = async (addressInfo: HomeAddress): Promise<void> => {
        const existingData = this.state;
        await this.setOnboardingData({
            piiData: {
                ...existingData?.piiData,
                address: {
                    ...existingData?.piiData?.address,
                    ...addressInfo
                }
            }
        });
    };

    saveSSN = async (identityInfo: string): Promise<void> => {
        const existingData = this.state;
        await this.setOnboardingData({
            piiData: {
                ...existingData?.piiData,
                ssn: identityInfo
            }
        });
    };

    setCurrentScreen = async (screenName: keyof RootStackParamList): Promise<void> => {
        const existingData = this.state;
        await this.setOnboardingData({
            ...existingData,
            currentScreen: screenName
        });
    };

    saveDateOfBirth = async (dateOfBirth: string): Promise<void> => {
        const existingData = this.state;
        await this.setOnboardingData({
            ...existingData,
            piiData: {
                ...existingData.piiData,
                dob: dateOfBirth
            }
        });
    };

    savePii = async (): Promise<void> => {
        this.setState({ isLoading: true });

        const pii = this.state.piiData;

        try {
            await CustomerService.updateCustomer({
                first_name: pii.firstName,
                middle_name: pii.middleName,
                last_name: pii.lastName,
                phone: pii.mobileNumber,
                dob: pii.dob,
                ssn: pii.ssn,
                address: {
                    street1: pii.address.streetName,
                    street2: pii.address.aptSuiteName,
                    city: pii.address.city,
                    state: pii.address.state,
                    postal_code: pii.address.zipCode
                }
            });
        } catch (err) {
            this.setState({ isLoading: false });
            throw err;
        }

        this.setState({ isLoading: false });
    };

    submitApplication = async (): Promise<void> => {
        await UserMarketService.createUserMarket(this.state.piiData.marketCode);
        await CharityService.updateUserCharity(this.state.piiData.charityId);
        await CustomerService.requestIdentityVerification();
    };

    updateIsNewUser = (value: boolean): void => {
        this.setState({ isNewUser: value });
    };

    cleanUpOnboarding = async (): Promise<void> => {
        await this.promiseSetState({ isNewUser: true });
    };

    render(): JSX.Element {
        return (
            <OnboardingContext.Provider
                value={{
                    isLoading: this.state.isLoading,
                    workflowUid: this.state.workflowUid,
                    currentScreen: this.state.currentScreen,
                    termsAndConditions: this.state.termsAndConditions,
                    patriotActDocument: this.state.patriotActDocument,
                    piiData: this.state.piiData,
                    bankingDisclosures: this.state.bankingDisclosures,
                    onboardingNotFinished: this.state.onboardingNotFinished,
                    setOnboardingNotFinished: this.setOnboardingNotFinished,
                    setCurrentScreen: this.setCurrentScreen,
                    setTermsAndConditions: this.setTermsAndConditions,
                    setBankingDisclosures: this.setBankingDisclosures,
                    acceptAllTerms: this.acceptAllTerms,
                    acceptPatriotAct: this.acceptPatriotAct,
                    acceptAllBankingDisclosures: this.acceptAllBankingDisclosures,
                    loadPatriotActDocuments: this.loadPatriotActDocuments,
                    loadBankingDisclosures: this.loadBankingDisclosures,
                    saveSelectedMarket: this.saveSelectedMarket,
                    saveSelectedCharity: this.saveSelectedCharity,
                    savePersonalInfo: this.savePersonalInfo,
                    saveHomeAddress: this.saveHomeAddress,
                    saveDateOfBirth: this.saveDateOfBirth,
                    saveSSN: this.saveSSN,
                    savePii: this.savePii,
                    submitApplication: this.submitApplication,
                    isNewUser: this.state.isNewUser,
                    updateIsNewUser: this.updateIsNewUser,
                    cleanUpOnboarding: this.cleanUpOnboarding
                }}
            >
                {this.props.children}
            </OnboardingContext.Provider>
        );
    }
}

export const OnboardingConsumer = OnboardingContext.Consumer;

export const useOnboarding = (): OnboardingContextProps => useContext(OnboardingContext);
