import { screenLoaded } from '@latitude/utils/analytics';
import { useHeaderHeight } from '@react-navigation/elements';
import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import {
    NativeScrollEvent,
    NativeSyntheticEvent,
    RefreshControl,
    StyleSheet,
    View
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import { headerOffset, navHeight } from '../constants/Layout';
import { HERO_HEIGHT } from './Hero';
import { ScrollView, useThemeColor } from './Themed';

export type LatScreenProps = View['props'] & {
    useScrollView?: boolean;
    hero?: React.ReactNode;
    hideNavBackground?: boolean;
    onRefresh?: () => Promise<any>;
    headerTransparent?: boolean;
    backgroundColor?: string;
};

export const LatScreen = (props: LatScreenProps): JSX.Element => {
    const {
        backgroundColor,
        hero,
        useScrollView,
        style,
        onRefresh,
        headerTransparent,
        ...otherProps
    } = props;
    const route = useRoute();
    const [navBackgroundOpacity, setNavBackgroundOpacity] = useState<number>(0);
    const [refreshing, setRefreshing] = React.useState(false);

    const headerHeight = useHeaderHeight();
    const heroHeight = HERO_HEIGHT;
    const dark = useThemeColor('dark');

    const isDesktop = useMediaQuery({ minWidth: 992 });

    const defaultStyles = StyleSheet.create({
        wrapper: {
            flex: 1,
            backgroundColor: backgroundColor || dark,
            paddingTop: headerHeight === 0 ? headerOffset : 0,
            overflow: 'hidden'
        },
        safeAreaView: {
            flex: 1,
            justifyContent: 'space-between',
            backgroundColor: backgroundColor || dark
        },
        latScreen: {
            flex: 1,
            paddingHorizontal: 16
        },
        container: {
            flex: 1
        },
        webContainer: {
            width: '100%',
            maxWidth: 1300,
            alignItems: 'center',
            justifyContent: 'center'
        },
        navBackground: {
            position: 'absolute',
            zIndex: 1,
            opacity: navBackgroundOpacity,
            height: navHeight,
            width: '100%',
            backgroundColor: dark,
            shadowColor: '#000000',
            shadowOffset: {
                width: 0,
                height: 20
            },
            shadowOpacity: 0.4,
            shadowRadius: 20,
            elevation: 20
        }
    });

    useEffect(() => {
        screenLoaded('Latitude', route.name);
    }, []);

    const refreshControl = onRefresh ? (
        <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
                setRefreshing(true);
                onRefresh().finally(() => {
                    setRefreshing(false);
                });
            }}
            tintColor={useThemeColor('regular')}
            style={{
                zIndex: 9999
            }}
        />
    ) : undefined;

    const Container = useScrollView ? ScrollView : View;

    const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>): void => {
        const y = event.nativeEvent.contentOffset.y;
        const opaquePoint = heroHeight - navHeight;

        if (y >= opaquePoint) {
            setNavBackgroundOpacity(1);
        } else {
            setNavBackgroundOpacity(y / opaquePoint);
        }
    };

    return (
        <View
            style={[
                defaultStyles.wrapper,
                isDesktop && { justifyContent: 'center', alignItems: 'center' }
            ]}
        >
            {hero && !props.hideNavBackground && <View style={defaultStyles.navBackground} />}
            <Container
                style={[defaultStyles.container, isDesktop && defaultStyles.webContainer]}
                contentInset={{ top: headerTransparent ? headerHeight * 0.7 : 0 }}
                contentOffset={{ x: 0, y: headerTransparent ? -headerHeight * 0.7 : 0 }}
                contentInsetAdjustmentBehavior={hero ? 'never' : 'always'}
                onScroll={handleScroll}
                scrollEventThrottle={16}
                keyboardShouldPersistTaps="handled"
                refreshControl={refreshControl}
                contentContainerStyle={{ marginTop: headerTransparent ? -headerHeight * 0.7 : 0 }}
                showsVerticalScrollIndicator={false}
            >
                {hero}
                <SafeAreaView
                    style={defaultStyles.safeAreaView}
                    edges={['bottom', 'left', 'right']}
                >
                    <View style={[defaultStyles.latScreen, style]} {...otherProps} />
                </SafeAreaView>
            </Container>
        </View>
    );
};
