import storage from '@latitude/utils/storage';
import { merge } from 'lodash';
import { useEffect } from 'react';
import create, { StateCreator } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import { AccountsState, initAccountsSlice } from './Accounts';
import { CharityState, initCharitySlice } from './Charity';
import { VendorState, initVendorSlice } from './Vendor';
import { initMarketSlice, MarketState } from './Market';
import { initPointsSlice, PointsState } from './Points';
import slice from './slice';
import { initSubscriptionSlice, SubscriptionState } from './Subscription';

interface ApplicationState {
    market: MarketState;
    accounts: AccountsState;
    points: PointsState;
    subscription: SubscriptionState;
    charity: CharityState;
    vendor: VendorState;
    init: () => void;
    reset: () => void;
}

type LatitudePersist = (
    config: StateCreator<ApplicationState>,
    options: PersistOptions<ApplicationState>
) => StateCreator<ApplicationState>;

/**
   The root state hook. For testing, we sub out `zustand/vanilla` for
   `zustand/react`, see: `ROOT/__mocks__/zustand.js`. Vanilla Zustand
   changes `useApplicationState` from a hook function into an object
   with `get/setState()` functions.

   To test application state, assuming you are in the state/__tests__
   directory:

       import { useApplicationState as AppState } from '../index';

       describe('My state test', () => {
           const slice = AppState.getState().mySliceName;

           slice.performAction();
           const updated = AppState.getState().mySliceName;
           expect(updated.value).toEqual('my expected value');
       });
 */
export const useApplicationState = create<ApplicationState>(
    (persist as unknown as LatitudePersist)(
        (set, get) => {
            const createSlice = slice(set, get);

            const market = createSlice<'market'>('market', initMarketSlice);
            const accounts = createSlice<'accounts'>('accounts', initAccountsSlice);
            const points = createSlice<'points'>('points', initPointsSlice);
            const charity = createSlice<'charity'>('charity', initCharitySlice);
            const subscription = createSlice<'subscription'>('subscription', initSubscriptionSlice);
            const vendor = createSlice<'vendor'>('vendor', initVendorSlice);

            return {
                market,
                accounts,
                points,
                subscription,
                charity,
                vendor,
                init: () => {
                    market.init();
                    subscription.init();
                },
                reset: () => {
                    // Resets all user related state
                    accounts.reset();
                    points.reset();
                }
            };
        },
        {
            name: 'latitude-store', // unique name
            getStorage: () => storage,
            version: 1,
            merge(persistedState, currentState) {
                if (persistedState) {
                    const mergedState = merge(currentState, persistedState);
                    return mergedState;
                }
                return currentState;
            }
        }
    )
);

export const useInit = (): void => {
    const init = useApplicationState((s) => s.init);

    useEffect(() => {
        init();
    }, []);
};

const selectMarket = (s: ApplicationState) => s.market;
export const useMarket = (): MarketState => useApplicationState(selectMarket);

const selectAccounts = (s: ApplicationState) => s.accounts;
export const useAccounts = (): AccountsState => useApplicationState(selectAccounts);

const selectPoints = (s: ApplicationState) => s.points;
export const usePoints = (): PointsState => useApplicationState(selectPoints);

const selectSubscription = (s: ApplicationState) => s.subscription;
export const useSubscription = (): SubscriptionState => useApplicationState(selectSubscription);

const selectCharity = (s: ApplicationState) => s.charity;
export const useCharity = (): CharityState => useApplicationState(selectCharity);

const selectVendor = (s: ApplicationState) => s.vendor;
export const useVendor = (): VendorState => useApplicationState(selectVendor);

// useApplicationState.subscribe((s) => {
//     console.log(JSON.stringify(s, null, 2));
// });

export default useApplicationState;
