import { mapToVendor, Vendor } from '@latitude/models/Vendor';
import VendorService from '@latitude/services/VendorService';
import { flatten, isNil, omitBy, uniqWith } from 'lodash';
import { InitSlice } from './slice';

const perPage = 25;

export interface VendorState {
    isLoading: boolean;
    hasMore: (params?: { marketId?: number }) => boolean;
    vendors: {
        [marketId: string]: {
            hasMore: boolean;
            data: Array<Array<Vendor>>;
        };
    };
    getVendors: (params?: { marketId?: number }) => Vendor[];
    loadVendors: (params?: { marketId?: number }) => Promise<void>;
}

export const initVendorSlice: InitSlice<VendorState> = (set, get) => ({
    isLoading: false,
    vendors: {},
    hasMore: (params) => {
        const marketId = params?.marketId ?? 0;
        const pages = get().vendors?.[marketId] ?? {};
        return pages.hasMore !== false;
    },
    getVendors: (params): Vendor[] => {
        const marketId = params?.marketId ?? 0;
        const pages = get().vendors?.[marketId]?.data ?? [];
        return uniqWith(flatten(pages), (a, b) => a.vendorId === b.vendorId);
    },
    loadVendors: async (params) => {
        const marketId = params?.marketId ?? 0;
        const vendors = get().vendors;
        const page = vendors[marketId]?.data?.length ?? 0;

        try {
            set({ isLoading: true });
            const query = omitBy(
                {
                    offset: perPage * page,
                    limit: perPage,
                    sort: '["name", "asc"]',
                    ...(marketId ? { filter: { market_id: marketId } } : {})
                },
                isNil
            );
            const { data } = await VendorService.getVendors(query);
            const mappedVendors = data.map(mapToVendor) as Vendor[];
            // If not requested for a specific market we'll dump the result into 0
            set((prev) => {
                const nextVendors = { ...prev.vendors };
                if (!nextVendors[marketId]) nextVendors[marketId] = { data: [], hasMore: true };
                if (mappedVendors.length) {
                    nextVendors[marketId].data.push(mappedVendors);
                } else {
                    nextVendors[marketId].hasMore = false;
                }
                return { ...prev, vendors: nextVendors };
            });
        } catch (e) {
            console.log(e);
        } finally {
            set({ isLoading: false });
        }
    }
});
