import { PointType } from './Point';
import { SyntheticAccount } from './SyntheticAccount';

export enum TransactionType {
    AtmWithdrawal = 'atm_withdrawal',
    CardPurchase = 'card_purchase',
    CardRefund = 'card_refund',
    Deposit = 'deposit',
    Dispute = 'dispute',
    ExternalTransfer = 'external_transfer',
    Fee = 'fee',
    generalSyntheticToGeneralSyntheticTransfer = 'general_synthetic_to_general_synthetic_transfer',
    InternalTransfer = 'internal_transfer',
    ReversedTransfer = 'reversed_transfer',
    ThirdPartyTransfer = 'third_party_transfer',
    UnknownDeposit = 'unknown_deposit',
    UnknownWithdrawal = 'unknown_withdrawal',
    Withdrawal = 'withdrawal'
}

export enum TransactionStatus {
    Queued = 'queued',
    Pending = 'pending',
    Settled = 'settled',
    Failed = 'failed'
}

export const TransferTransactionTypes: TransactionType[] = [
    TransactionType.ExternalTransfer,
    TransactionType.generalSyntheticToGeneralSyntheticTransfer,
    TransactionType.InternalTransfer
];

export enum TransactionNetAsset {
    Positive = 'positive',
    Negative = 'negative',
    Neutral = 'neutral'
}

export interface MxData {
    guid: string;
    memo: string | null;
    status: string;
    user_id: string;
    category: string;
    latitude: string | null;
    metadata: string | null;
    longitude: string | null;
    member_id: string;
    posted_at: number;
    posted_on: string;
    user_guid: string;
    account_id: string;
    check_image: string | null;
    description: string | null;
    member_guid: string;
    account_guid: string;
    check_number: string | null;
    category_guid: string;
    currency_code: string | null;
    merchant_guid: string | null;
    transacted_at: number;
    transacted_on: string;
    localized_memo: string | null;
    is_international: boolean | null;
    localized_description: string | null;
    merchant_category_code: string | null;
    merchant_location_guid: string | null;
    extended_transaction_type: string | null;
}

export interface Vendor {
    name: string;
    image?: string;
}

export interface Transaction {
    uid: string;
    settledIndex: number | null;
    transferUid: string | null;
    sourceSyntheticAccountUid: string;
    destinationSyntheticAccountUid: string;
    status: TransactionStatus;
    usDollarAmount: string;
    type: TransactionType;
    netAsset: TransactionNetAsset;
    description: string;
    createdAt: Date;
    settledAt?: Date | null;
    account?: SyntheticAccount;
    // Only included for V2 Transactions
    merchantName: string | null;
    mxData?: MxData | null;
    pointType?: PointType | null;
    pointCount?: number | null;
    charityDonationUsCents?: number | null;
    cashbackUsCents?: number | null;
    vendorId?: number;
    vendorName?: string;
    vendorImage?: string;
}

export const mapToTransaction = (data: any): Transaction => {
    if (!data) {
        return null;
    }

    return {
        uid: data.uid ?? data.rize_uid, // For V2 Transactions
        settledIndex: data.settled_index,
        transferUid: data.transfer_uid,
        sourceSyntheticAccountUid: data.source_synthetic_account_uid,
        destinationSyntheticAccountUid: data.destination_synthetic_account_uid,
        status: data.status,
        usDollarAmount: data.us_dollar_amount,
        type: data.type,
        netAsset: data.net_asset,
        description: data.description,
        mxData: data.mx_data,
        merchantName: data.merchant_name,
        pointType: data.point_type,
        pointCount: data.point_count,
        charityDonationUsCents: data.charity_donation_us_cents,
        cashbackUsCents: data.cashback_us_cents,
        createdAt: new Date(data.date_created),
        settledAt: data.settled_at ? new Date(data.settled_at) : undefined,
        vendorId: data.vendor_id,
        vendorName: data.vendor_name,
        vendorImage: data.vendor_image
    };
};

export const getTransactionMethod = (transaction: Transaction): string => {
    const types = TransactionType;
    const methods = {
        [types[types.AtmWithdrawal]]: 'Card Purchase',
        [types[types.CardPurchase]]: 'Card Purchase',
        [types[types.CardRefund]]: 'Card Refund',
        [types[types.Deposit]]: 'Other',
        [types[types.Dispute]]: 'Temp Credit',
        [types[types.ExternalTransfer]]: 'Transfer',
        [types[types.Fee]]: 'Fee',
        [types[types.generalSyntheticToGeneralSyntheticTransfer]]: 'Other',
        [types[types.InternalTransfer]]: 'Transfer',
        [types[types.ReversedTransfer]]: 'Transfer',
        [types[types.ThirdPartyTransfer]]: 'Transfer',
        [types[types.UnknownDeposit]]: 'Other',
        [types[types.UnknownWithdrawal]]: 'Other',
        [types[types.Withdrawal]]: 'Card Purchase'
    };

    return methods[transaction.type] || 'Other';
};

export const isTransferTransaction = (transaction: Transaction): boolean => {
    return TransferTransactionTypes.includes(transaction.type);
};

export const transactionDescription = (transaction: Transaction): string => {
    const isTransfer = isTransferTransaction(transaction);
    const { description, vendorName } = transaction;
    if (isTransfer) return description;
    if (vendorName) return vendorName;
    return transaction.mxData?.description ?? description;
};
