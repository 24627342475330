import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" width={34} height={34} {...props}>
        <Path
            d="m9.222 7.808 7.777 7.777 7.78-7.777 1.413 1.414L18.412 17l7.78 7.778-1.414 1.414-7.78-7.778-7.776 7.778-1.414-1.414L15.585 17 7.808 9.222l1.414-1.414z"
            fill="#FFF"
            fillRule="evenodd"
        />
    </Svg>
);

export default SvgComponent;
