import { Plan } from '@latitude/models/Subscription';
import api, { ApiResponse } from '../utils/api';

export const getPlans = async (limit = 20, offset = 0): Promise<ApiResponse<Plan[]>> => {
    return await api
        .get('/plan/', {
            params: {
                limit,
                offset
            }
        })
        .then((response) => response.data);
};

export default {
    getPlans
};
