import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" width={14} height={14} {...props}>
        <Path
            d="m.636 9.121 4.243 4.243 8.485-8.485"
            stroke="#009e93"
            strokeWidth={2}
            fill="none"
            fillRule="evenodd"
        />
    </Svg>
);

export default SvgComponent;
