// Reference: https://www.debuggr.io/react-update-unmounted-component/
import { MutableRefObject, useEffect, useRef } from 'react';

const useIsMountedRef = (): MutableRefObject<any> => {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return (): boolean => (isMountedRef.current = false);
    });
    return isMountedRef;
};

export default useIsMountedRef;
