import { DebitCard } from '.';

export enum SyntheticAccountCategory {
    General = 'general',
    External = 'external',
    PlaidExternal = 'plaid_external',
    OutboundAch = 'outbound_ach',
    TargetYieldaccount = 'target_yield_account'
}

export enum SyntheticAccountStatus {
    Good = 'good',
    Active = 'active',
    Archived = 'archived'
}

export interface SyntheticAccount {
    uid: string;
    name: string;
    accountNumber: string;
    accountNumberLastFour?: string;
    routingNumber: string;
    syntheticAccountCategory: SyntheticAccountCategory;
    syntheticAccountTypeUid?: string;
    externalUid?: string;
    status?: SyntheticAccountStatus;
    liability?: boolean;
    netUsdBalance?: string | null;
    netUsdPendingBalance?: string | null;
    netUsdAvailableBalance?: string | null;
    masterAccount?: boolean;
    openedAt?: string | null;
    closedAt?: string | null;
    closedToSyntheticAccountUid?: string | null;

    // Custom properties
    isPrimary?: boolean;
    displayName?: string;
    debitCard?: DebitCard;
}

export const mapToSyntheticAccount = (data: any): SyntheticAccount => {
    if (!data) {
        return null;
    }

    return {
        uid: data.uid,
        name: data.name,
        accountNumber: data.account_number,
        accountNumberLastFour: data.account_number_last_four,
        routingNumber: data.routing_number,
        syntheticAccountCategory: data.synthetic_account_category,
        syntheticAccountTypeUid: data.synthetic_account_type_uid,
        externalUid: data.external_uid,
        status: data.status,
        liability: data.liability,
        netUsdBalance: data.net_usd_balance,
        netUsdPendingBalance: data.net_usd_pending_balance,
        netUsdAvailableBalance: data.net_usd_available_balance,
        masterAccount: data.master_account,
        openedAt: data.opened_at,
        closedAt: data.closed_at,
        closedToSyntheticAccountUid: data.closed_to_synthetic_account_uid,

        // Custom properties
        isPrimary: data.master_account && data.liability,
        displayName: data.master_account && data.liability ? 'Checking' : data.name
    };
};
