import { LatLogo } from '@latitude/components/LatLogo';
import { useThemeColor } from '@latitude/components/Themed';
import { LoadingProvider } from '@latitude/contexts/Loading';
import InvitePendingScreen from '@latitude/screens/InvitePendingScreen';
import MembershipScreen from '@latitude/screens/onboarding/MembershipScreen';
import RequestInviteScreen from '@latitude/screens/RequestInviteScreen';
import { RootStackParamList } from '@latitude/types';
import { DarkTheme, DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack';
import * as React from 'react';
import { ColorSchemeName, TouchableOpacity, View } from 'react-native';
import LinkingConfiguration from './LinkingConfiguration';

const MainStack = createStackNavigator<RootStackParamList>();

const withStandardHeader: StackNavigationOptions = {
    headerShown: true,
    headerStyle: {
        height: 84,
        backgroundColor: '#242225',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 20
        },
        shadowOpacity: 0.4,
        shadowRadius: 20,
        elevation: 20,
        borderBottomWidth: 0
    },
    headerTitle: () => {
        return (
            <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <View style={{ maxWidth: 1300, width: '100%' }}>
                    <TouchableOpacity
                        onPress={() => {
                            window.location.href = 'https://www.getlatitude.com';
                        }}
                    >
                        <LatLogo.Medium style={{ width: 144, height: 18, position: 'relative' }} />
                    </TouchableOpacity>
                </View>
            </View>
        );
    }
};

const OnboardingStackScreen = (): JSX.Element => {
    return (
        <View style={{ flex: 1, backgroundColor: useThemeColor('dark') }}>
            <MainStack.Navigator screenOptions={withStandardHeader}>
                <MainStack.Screen
                    name="RequestInvite"
                    component={RequestInviteScreen}
                    options={{ title: 'Request Invite' }}
                />
                <MainStack.Screen name="Membership" component={MembershipScreen} />
                <MainStack.Screen
                    name="InvitePending"
                    component={InvitePendingScreen}
                    options={{ title: 'Invite Pending' }}
                />
            </MainStack.Navigator>
        </View>
    );
};

export const Navigation = ({ colorScheme }: { colorScheme: ColorSchemeName }): JSX.Element => {
    return (
        <NavigationContainer
            linking={LinkingConfiguration}
            theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
        >
            <LoadingProvider>
                <OnboardingStackScreen />
            </LoadingProvider>
        </NavigationContainer>
    );
};

export default Navigation;
