import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SvgComponent = (props: SvgProps) => (
    <Svg xmlns="http://www.w3.org/2000/svg" width={18} height={12} {...props}>
        <Path
            d="M15.364 11.121a1 1 0 0 1-1.414 0L9 6.171l-4.95 4.95a1 1 0 0 1-1.414 0L1.222 9.707a1 1 0 0 1 0-1.414l7.07-7.071a1 1 0 0 1 1.415 0l7.071 7.07a1 1 0 0 1 0 1.415l-1.414 1.414z"
            fill="#B38B7D"
            fillRule="evenodd"
        />
    </Svg>
);

export default SvgComponent;
