import * as React from 'react';
import {
    Text as DefaultText,
    View as DefaultView,
    ScrollView as DefaultScrollView,
} from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

export const useThemeColor = (
    colorName: keyof typeof Colors.light & keyof typeof Colors.dark,
    props?: { light?: string; dark?: string },
): string => {
    const theme = useColorScheme();
    const colorFromProps = props && props[theme];

    if (colorFromProps) {
        return colorFromProps;
    } else {
        return Colors[theme][colorName];
    }
};

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
};

export type TextProps = ThemeProps & DefaultText['props'];
export type ViewProps = ThemeProps & DefaultView['props'];
export type ScrollViewProps = ThemeProps & DefaultScrollView['props'];

export const Text = (props: TextProps): JSX.Element => {
    const { style, lightColor, darkColor, ...otherProps } = props;
    const color = useThemeColor('regular', {
        light: lightColor,
        dark: darkColor,
    });

    return <DefaultText style={[{ color }, style]} {...otherProps} />;
};

export const View = (props: ViewProps): JSX.Element => {
    const { style, lightColor, darkColor, ...otherProps } = props;
    const backgroundColor = useThemeColor('dark', {
        light: lightColor,
        dark: darkColor,
    });

    return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
};

export const ScrollView = (props: ScrollViewProps): JSX.Element => {
    const { style, lightColor, darkColor, ...otherProps } = props;
    const backgroundColor = useThemeColor('dark', {
        light: lightColor,
        dark: darkColor,
    });

    return (
        <DefaultScrollView style={[{ backgroundColor }, style]} {...otherProps} />
    );
};
