import React, { useEffect, useState } from 'react';
import { Keyboard, LayoutChangeEvent, Pressable, ScrollView, StyleSheet, View } from 'react-native';
import { CheckSuccess, ChevronDown, ChevronUp } from '../svg';
import { Body, TextLinkButton } from './StyledText';
import { useThemeColor } from './Themed';

export type LatDropdownItem = {
    text: string;
    value: string;
};

export type LatDropdownProps = {
    placeholder?: string;
    items: LatDropdownItem[];
    ctaText?: string;
    zIndex?: number;
    selectedIndex?: number;
    disabled?: boolean;
    error?: boolean;
    isOpen?: boolean;
    onChange?: (item: LatDropdownItem, index: number) => void;
    onCtaPress?: () => void;
    onChangeIsOpen?: (value: boolean) => void;
    testID?: string;
};

const LatDropdown = (props: LatDropdownProps): JSX.Element => {
    const [dropdownBarHeight, setDropdownBarHeight] = useState<number>(0);
    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen ?? false);
    const [selectedIndex, setSelectedIndex] = useState<number>(props.selectedIndex);
    const selectedItem =
        selectedIndex === undefined || selectedIndex < 0 || props.items.length === 0
            ? undefined
            : props.items[selectedIndex];

    const zIndex = props.zIndex ?? 1;

    const background = useThemeColor('background');
    const borders = useThemeColor('borders');
    const primary = useThemeColor('primary');
    const errorColor = useThemeColor('error');
    const light = useThemeColor('light');

    const defaultStyles = StyleSheet.create({
        container: {
            zIndex: zIndex
        },
        dropDownBar: {
            backgroundColor: background,
            borderColor: isOpen ? primary : background,
            borderWidth: 1,
            borderRadius: 8,
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 24,
            paddingVertical: 12,
            opacity: props.disabled ? 0.5 : 1
        },
        dropDownBarText: {
            flex: 1,
            height: 24
        },
        drop: {
            backgroundColor: background,
            borderColor: borders,
            borderWidth: 1,
            borderRadius: 8,
            position: 'absolute',
            top: dropdownBarHeight + 8,
            width: '100%',
            zIndex: zIndex + 1,
            display: props.disabled ? 'none' : 'flex'
        },
        dropOptions: {
            maxHeight: 184,
            marginVertical: 8,
            paddingHorizontal: 38
        },
        option: {
            marginVertical: 8
        },
        blur: {
            height: 13,
            marginTop: -13,
            opacity: 0.8,
            marginHorizontal: props.ctaText ? 16 : 0,
            backgroundColor: background
        },
        divider: {
            borderTopWidth: 1,
            marginHorizontal: 16,
            borderColor: borders
        },
        cta: {
            marginVertical: 16,
            marginHorizontal: 38
        },
        borderError: {
            borderWidth: 1,
            borderColor: errorColor
        },
        placeholder: {
            color: light
        }
    });

    const onDropdownBarPress = (): void => {
        setIsOpen((open) => !open);
        Keyboard.dismiss();
    };

    const onDropdownBarLayout = (event: LayoutChangeEvent): void => {
        setDropdownBarHeight(event.nativeEvent.layout.height ?? 0);
    };

    useEffect(() => {
        if (props.onChange) {
            props.onChange(selectedItem, selectedIndex);
        }
    }, [selectedIndex]);

    useEffect(() => {
        if (selectedIndex !== props.selectedIndex) {
            setSelectedIndex(props.selectedIndex);
        }
    }, [props.selectedIndex]);

    useEffect(() => {
        if (props.onChangeIsOpen) {
            props.onChangeIsOpen(isOpen);
        }
    }, [isOpen]);

    useEffect(() => {
        if (props.isOpen !== isOpen) {
            setIsOpen(props.isOpen);
        }
    }, [props.isOpen]);

    const renderOptions = (
        items: LatDropdownItem[],
        ctaText?: string,
        onCtaPress?: () => void
    ): JSX.Element => {
        const onTextLinkPress = (): void => {
            if (onCtaPress) {
                onCtaPress();
            }
        };

        const onOptionPress = (index: number): void => {
            setSelectedIndex(index);
            setIsOpen(false);
        };

        return (
            <View style={defaultStyles.drop}>
                <ScrollView style={defaultStyles.dropOptions} bounces={false}>
                    {items.map((item, idx) => (
                        <Pressable
                            key={idx}
                            style={defaultStyles.option}
                            onPress={(): void => onOptionPress(idx)}
                            testID={props.testID ? `${props.testID}-${idx}` : undefined}
                        >
                            {idx === selectedIndex && (
                                <CheckSuccess
                                    height={12}
                                    width={12}
                                    style={{ position: 'absolute', left: -20, top: 4 }}
                                />
                            )}
                            <Body colorScheme={idx === selectedIndex ? 'primaryLight' : 'regular'}>
                                {item.text}
                            </Body>
                        </Pressable>
                    ))}
                </ScrollView>
                {items.length >= 5 && <View style={defaultStyles.blur} />}
                {!!ctaText && (
                    <>
                        <View style={defaultStyles.divider} />
                        <TextLinkButton style={defaultStyles.cta} onPress={onTextLinkPress}>
                            {ctaText}
                        </TextLinkButton>
                    </>
                )}
            </View>
        );
    };

    return (
        <View style={defaultStyles.container} testID={props.testID}>
            <Pressable
                style={[defaultStyles.dropDownBar, props.error && defaultStyles.borderError]}
                onPress={onDropdownBarPress}
                onLayout={onDropdownBarLayout}
                disabled={props.disabled}
            >
                <Body
                    style={[
                        defaultStyles.dropDownBarText,
                        !selectedItem && defaultStyles.placeholder
                    ]}
                    colorScheme={selectedIndex === undefined ? 'light' : 'regular'}
                >
                    {!selectedItem ? props.placeholder ?? ' ' : selectedItem.text}
                </Body>
                {!isOpen && <ChevronDown />}
                {isOpen && <ChevronUp />}
            </Pressable>
            {isOpen && renderOptions(props.items, props.ctaText, props.onCtaPress)}
        </View>
    );
};

export default LatDropdown;
