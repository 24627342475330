export interface Vendor {
    vendorId: number;
    name: string;
    imageUrl?: string;
    marketId: number;
    dateCreated: Date;
    dateUpdated: Date;
    website: string;
    latitude: number;
    longitude: number;
    tagline?: string;
    fullAddress: string;
    promotion?: string;
    description: string;
    cashbackEnabled: boolean;
}

export const mapToVendor = (data: any): Vendor | undefined => {
    if (!data) {
        return undefined;
    }

    return {
        dateCreated: new Date(data.date_created),
        dateUpdated: new Date(data.date_updated),
        description: data.description,
        fullAddress: data.full_address,
        imageUrl: data.image,
        latitude: data.latitude,
        longitude: data.longitude,
        marketId: data.market_id,
        name: data.name,
        promotion: data.promotion,
        tagline: data.tagline,
        vendorId: data.vendor_id,
        website: data.website,
        cashbackEnabled: !!data.cashback_enabled
    };
};
