import { GetState, SetState } from 'zustand';

type AppState = Record<string, any>;

type SliceKey<S extends AppState> = {
    [K in keyof S]: S[K] extends S ? K : never;
}[keyof AppState];

export const sliceGet = <S extends AppState, K extends SliceKey<S>>(
    get: GetState<S>,
    key: K
): GetState<S[K]> => {
    return () => get()[key];
};

export const sliceSet = <S extends AppState, K extends SliceKey<S>>(
    set: SetState<S>,
    key: K
): SetState<S[K]> => {
    return (...params: Parameters<SetState<S[K]>>) => {
        const [partial, replace] = params;

        set((state) => {
            const nextState =
                typeof partial === 'function'
                    ? (partial as (s: S[K]) => S[K])(state[key])
                    : partial;

            if (replace) {
                return { [key]: nextState } as Partial<S>;
            }

            return {
                [key]: {
                    ...state[key],
                    ...nextState
                }
            } as Partial<S>;
        });
    };
};

export type InitSlice<S extends AppState> = (set: SetState<S>, get: GetState<S>) => S;

const slice =
    <S extends AppState>(set: SetState<S>, get: GetState<S>) =>
    <K extends SliceKey<S>>(k: K, init: InitSlice<S[K]>) =>
        init(sliceSet(set, k), sliceGet(get, k));

export default slice;
