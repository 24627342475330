import api from '../utils/api';

export const getPoints = async (limit = 20, offset = 0): Promise<any> => {
    return await api
        .get('/point/', {
            params: {
                limit,
                offset
            }
        })
        .then((response) => response.data);
};

export const getPointStats = async (include: string[]): Promise<any> => {
    return await api
        .get('/point/stats', {
            params: {
                include
            }
        })
        .then((response) => response.data);
};

export default {
    getPoints,
    getPointStats
};
